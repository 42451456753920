import React from "react";
import "./testominal.css";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const Testtominal = ({
  Para1,
  Para2,
  Para3,
  Para4,
  Name1,
  Name2,
  Name3,
  Name4,
}) => {
  const history = useHistory();
  const [showText, setShowText] = useState(true);
  const [showText1, setShowText1] = useState(true);
  const [showText2, setShowText2] = useState(true);
  const [showText3, setShowText3] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? false : true);
  };

  const toggleShowText = () => {
    setShowText(!showText);
  };
  const toggleShowText1 = () => {
    setShowText1(!showText1);
  };
  const toggleShowText2 = () => {
    setShowText2(!showText2);
  };
  const toggleShowText3 = () => {
    setShowText2(!showText3);
  };
  return (
    <>
      <div className=" testimonial">
        <Carousel className="">
          <Carousel.Item className="">
            <Container className="test_bg">
              <Row>
                <div className="testi_div">
                  <div className="">
                    <p className="test_text mx-auto w-100">
                      {isDesktop && showText ? Para1.slice(0, 100) : Para1}
                      <span onClick={toggleShowText} className="text_span">
                        {isDesktop
                          ? showText
                            ? "... Read more"
                            : " ... Show less"
                          : ""}
                      </span>
                    </p>
                  </div>
                  <div className="name_job">
                    <div>
                      <div>
                        <h2 className="testimonial_name "> {Name1}</h2>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container className="test_bg">
              <Row>
                <div className="testi_div">
                  <div className="">
                    <p className="test_text pt-3 mx-auto w-100 ">
                      {isDesktop && showText1 ? Para2.slice(0, 100) : Para2}
                      <span onClick={toggleShowText1} className="text_span">
                        {isDesktop
                          ? showText1
                            ? "... Read more"
                            : " ... Show less"
                          : ""}
                      </span>
                    </p>
                  </div>
                  <div className="name_job">
                    <div>
                      <div>
                        <h2 className="testimonial_name "> {Name2}</h2>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container className="test_bg">
              <Row>
                <Col md={12}>
                  <div className="testi_div">
                    <div className="">
                      <p className="test_text pt-3 mx-auto w-100">
                        {isDesktop && showText2 ? Para3.slice(0, 100) : Para3}
                        <span onClick={toggleShowText2} className="text_span">
                          {isDesktop
                            ? showText2
                              ? "... Read more"
                              : " ... Show less"
                            : ""}
                        </span>
                      </p>
                    </div>
                    <div className="name_job">
                      <div>
                        <div>
                          <h2 className="testimonial_name ">{Name3}</h2>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container className="test_bg">
              <Row>
                <Col md={12}>
                  <div className="testi_div">
                    <div className="">
                      <p className="test_text pt-3 mx-auto w-100">
                        {isDesktop && showText3 ? Para4.slice(0, 100) : Para4}
                        <span onClick={toggleShowText3} className="text_span">
                          {isDesktop
                            ? showText3
                              ? "... Read more"
                              : " ... Show less"
                            : ""}
                        </span>
                      </p>
                    </div>
                    <div className="name_job">
                      <div>
                        <div>
                          <h2 className="testimonial_name ">{Name4}</h2>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default Testtominal;
