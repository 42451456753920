import React, { useState, useEffect, useRef } from "react";
import InstagramFeed from "../InstagramFeed/Instagram.js";
import Appointment from "../Appointment/Appointment";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { HeaderWithRouter } from "../Header/Header";
import Testtominal from "../Testominal/Testtominal";
import { useLocation } from "react-router-dom";
import HeaderBanner from "../HeaderBanner/HeaderBanner.js";
import { BannerData } from "../Data/BannerData.js";
import Insta from "../Testominal/Insta.js";
import ReactWhatsapp from "react-whatsapp";
import Draggable from "react-draggable";
import cross from "../Assets/images/cross.png";
import ReactPixel from "react-facebook-pixel";

const MainLayout = (props) => {
  const location = useLocation().pathname;
  const locations = useLocation();
  const redCircleRef = useRef(null);

  const nodeRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  const onDrop = () => {
    if (!redCircleRef.current) return;

    if (
      redCircleRef?.current.getBoundingClientRect().top <=
        nodeRef?.current.getBoundingClientRect().bottom &&
      redCircleRef?.current.getBoundingClientRect().bottom >=
        nodeRef?.current.getBoundingClientRect().top &&
      redCircleRef?.current.getBoundingClientRect().left <=
        nodeRef?.current.getBoundingClientRect().right &&
      redCircleRef?.current.getBoundingClientRect().right >=
        nodeRef?.current.getBoundingClientRect().left
    ) {
      nodeRef.current.style.visibility = "hidden";
    }
  };
  const dragStart = (e) => {
    setDragging(true);
    setVisible(true);
  };

  const sentDirectLink = () => {
    const phn = 923229400555;

    window.open(
      `https://api.whatsapp.com/send/?phone=${phn}&text=Advance+Surgicare%21%21%21&type=phone_number&app_absent=0`,
      "localhost:3000"
    );
    ReactPixel.fbq("track", "Contact", {
      page: location,
      contactNumber: phn,
      contactType: "WhatsApp Floating Icon",
    });
  };

  const stopFunction = (e) => {
    if (isDesktop === false) {
      setDragging(false);
    }
    setVisible(false);
    if (dragging) {
      onDrop();
    } else {
      sentDirectLink();
    }
  };
  return (
    <>
      {/* <Header /> */}
      <HeaderWithRouter />

      {/* {location !== "/" && location !== "/termofuse" && !locations?.state?.data === 'blogs' && location !== "/privacypolicy" && location !== "/faq" && location !== "/blog" && location !== "/blogdetail/:id" && <HeaderBanner data={BannerData} />} */}
      {location !== "/" && <HeaderBanner data={BannerData} />}
      {props.children}

      {location !== "/contact-us" &&
        location !== "/faq" &&
        location !== "/blog" &&
        location !== "/termofuse" &&
        location !== "/privacypolicy" &&
        location !== "/search-result" &&
        location !== "/" &&
        locations?.state?.data !== "blogs" && (
          <Testtominal
            Para1={
              "I would like to thank everyone at Advanced Surgicare for doing my son's gall bladder surgery. Special thanks to Dr. Asim, of course. His excellent surgical skills cured my son and he is well now. Words aren't really enough for thanking you for your care and kindness during our time of need."
            }
            Name1={"Ahmed"}
            Para2={
              "I am so relieved that my breast conservation surgery is finally done. Everyone was so kind and helped me when I  panicked before the surgery. Thank you for saving my life that day and calming me down."
            }
            Name2={"Fariha"}
            Para3={
              "Dr. Asim provided me will all the information related to my weight loss surgery and responded to all my concerns and questions. At all times during the treatment, I had great confidence in him and his team. They provided me with the best possible care!"
            }
            Name3={"Murtaza"}
            Para4={
              "I want to praise your surgeons for treating me so well while repairing my hernia. I was very nervous because it was my first surgery. I'm surely going to book an appointment with Advance Surgicare in case of any emergency of this sort."
            }
            Name4={"Areeba"}
          />
        )}

      {location !== "/contact-us" &&
        location !== "/faq" &&
        location !== "/termofuse" &&
        location !== "/privacypolicy" &&
        locations?.state?.data !== "blogs" &&
        location !== "/search-result" &&
        location !== "/blog" && <Appointment />}

      <Draggable nodeRef={nodeRef} onDrag={dragStart} onStop={stopFunction}>
        <div ref={nodeRef}>
          <ReactWhatsapp
            number={"+923219400555"}
            message=""
            element="img"
          ></ReactWhatsapp>
        </div>
      </Draggable>
      {visible && !isDesktop && (
        <div className="delDiv" ref={redCircleRef}>
          <div className="delCircle">
            <img src={cross} alt="crossImage" className="delImage" />
          </div>
        </div>
      )}

      {location === "/services" && <Insta />}

      {location === "/" && <Insta />}
      <Footer />
    </>
  );
};

export default MainLayout;
