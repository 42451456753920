export const SurgicalType = {
  "Advance Laparoscopic Surgery": [
    "Laparoscopic Hepatobiliary Surgery",
    "Laparoscopic Hernia Surgery",
    "Laparoscopic Appendix & Intestinal Surgery",
    "Laparoscopic Solid Organ Surgery",
  ],
  "Bariatric Surgery": [
    "Diabetes",
    "Heart Diseases",
    "Hypertension",
    "Infertility",
    "Sleep Apnea",
    "Cancer",
    "Joint Pain",
    "Decreases Life Span",
    "Other Health Risks",
  ],
  "Endocrine Surgery": [
    "Thyroid Surgery",
    "Parathyroid Surgery",
    "Laparoscopic Adrenal Surgery",
  ],
  "CancerCare Clinic": [
    "Breast Tumors/ Cancer",
    "Thyroid Cancer",
    "Upper Abdominal Cancers",
    "Lower Abdominal Cancers",
  ],
  "Vascular Surgery": ["Venous Diseases", "Arterial Diseases"],
  "Women's Healthcare": [
    "Laparoscopic Gynaecological Services",
    "Breast Related Problems",
    "Breast Conservation Surgery",
    "Surgeries For Pelvic Disorders & Piles Clinic",
  ],
};
