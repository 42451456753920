import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import data1 from "../FAQS/data";
import "./Faqs.css";
const Question = ({ Title, SubTitle, ind, Open: Open1, id }) => {
  const url = window.location.pathname;

  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState(data1);
  const [Open, setOpen] = useState(false);

  const closeFun = () => {
    data.map((el) => {
    });
    setData(data);
    setOpen(false);
    setExpanded(false);
  };

  const openfunction = () => {
    setOpen(false);
    data.map((el) => {
      el.Open = false;
    });
    setData(data);
    const element = data.filter((ele) => {
      return Number(ele.id) == Number(id);
    });
    element[0].Open = true;
    setOpen(true);
    setExpanded(element[0].Open);
  };

  return (
    
    <article className="plus_div" key={ind}>
      <div className={expanded === true ? "fag_question" : "fag_question1"}>
        <button
          className="faq_btn"
          onClick={expanded ? closeFun : openfunction}
        >
          {expanded ? (
            <AiOutlineMinus
              className={url === "/faq" ? "icon_faq" : "icon_faq1"}
            />
          ) : (
            <AiOutlinePlus
              className={url === "/faq" ? "icon_faq" : "icon_faq1"}
            />
          )}
        </button>
        {url != "/faq" ? (
          <h4
            className={
              expanded === true
                ? "question_title1_otherpages"
                : "question_title_otherpages"
            }
          >
            {Title}
          </h4>
        ) : (
          <h4
            className={expanded === true ? "question_title1" : "question_title"}
          >
            {Title}
          </h4>
        )}
      </div>
      {url != "/faq" ? (
        <div className="answer_div">
          {Open && <p className="faq_answer_other">{SubTitle}</p>}
        </div>
      ) : (
        <div className="answer_div">
          {Open && <p className="faq_answer">{SubTitle}</p>}
        </div>
      )}
    </article>
  );
};

export default Question;
