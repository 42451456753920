import React, { useEffect, useState } from "react";
import "../Surgicare/surgicare.css";
import AdvanceSurgicare from "./AdvanceSurgicare";

const Surgicare = ({ Paragraph2, Button }) => {
  return (
    <>
      <AdvanceSurgicare />
    </>
  );
};

export default Surgicare;
