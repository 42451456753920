import React, { useEffect, useState } from "react";
import "../AboutUS/progress.css";
import bigImg from "../Assets/images/services/complete_range_section_2x.webp";
import { Link } from "react-router-dom";
import Progress from "../progress/Progress";
import Progress1 from "../progress/progress1";
import { Link as ScrollLink } from "react-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutProgress = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  return (
    <>
      <div className="about_progress">
        <div className="progress_main">
          <div className="heading_img">
            <LazyLoadImage delayTime={500} alt="logo" src={bigImg} />
          </div>
          <div className="wrapperDiv">
            <div className="progress_data">
              <div className="mission_div">
                <h5>To Our Patients</h5>
                <h6>We Offer A Complete Range Of Surgical Services</h6>
                <p>
                  Undergoing surgery and choosing the right surgical clinic is
                  equally important. When patients choose Advance Surgicare, we
                  understand that they have trusted us with their surgical care
                  needs. No matter which surgery you undergo, feel confident in
                  the care we provide, knowing that we have a team of
                  specialized surgeons who function using state-of-the-art
                  surgical equipment and technology so that you recover at the
                  earliest.
                </p>
              </div>
              <div className="circle_progress">
                <div className="yellow_circle">
                  <div className="progress_circle">
                    {" "}
                    <Progress />
                  </div>
                  <div className="yellow_circle_text">
                    {" "}
                    <h5>Successful Surgeries </h5>
                    <p>
                      By combining the best specialists and equipment, we
                      perform successful surgeries that help patients recover
                      from their condition.{" "}
                    </p>
                  </div>
                </div>
                <div className="yellow_circle">
                  <div className="progress_circle">
                    {" "}
                    <Progress1 />
                  </div>
                  <div className="yellow_circle_text">
                    {" "}
                    <h5>Accurate Diagnosis</h5>
                    <p>
                      Our commitment to delivering high-quality and safe
                      surgical care includes accurate diagnosis and prognosis of
                      the patient's condition.
                    </p>
                  </div>
                </div>
              </div>
              <div className="about_progress_butotn_div">
                <ScrollLink
                  to="contactus"
                  spy={true}
                  smooth={true}
                  duration={200}
                >
                  {" "}
                  <button className="about_progress_btn">
                    Consult Now
                  </button>{" "}
                </ScrollLink>
                <Link to="/services">
                  {" "}
                  <button className="about_progress_btn1">
                    Explore Services
                  </button>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="periorty">
        <h6>Changing The Way Surgeries Are Performed</h6>
        <p>
          Dr. Aasim has devoted 20+ years to providing exceptional surgical
          care. The close collaboration between him and his team of surgeons at
          Advance Surgicare improves every patient's quality of life by using
          non-invasive surgical techniques like laparoscopy and keeps on
          discovering more ways to prevent and control their condition in the
          future. We believe in compassionate care, where no patients feel
          unrushed and reassured. While performing every surgery, we hold onto
          the highest clinical standards, keeping patient satisfaction our
          primary goal. When you walk to us for your treatment, know that you
          are in good hands.
        </p>{" "}
        <ScrollLink to="contactus" spy={true} smooth={true} duration={200}>
          <button className="periorty_btn">Consult A Surgeon</button>
        </ScrollLink>
      </div>
    </>
  );
};

export default AboutProgress;
