import React from "react";
import questions from "./data";
import SingleQuestion from "./Question";
import "./Faqs.css";
const Accordian1 = ({ data }) => {
  const url = window.location.pathname;

  return (
    <>
      <div className={url === "/faq" ? "faq_main1" : "faq_main1_otherpages"}>
        <div className="accordian_body1">
          <div className="containerw">
            <div className="row row_Accordiab">
              {data
                ? data?.FAQArray.map((question, i) => (
                    <div
                      className="col-12 "
                      key={i}
                      style={{ paddingRight: "0px !important" }}
                    >
                      <SingleQuestion
                        ind={i}
                        {...question}
                        key={i}
                        id={i + 1}
                      />
                    </div>
                  ))
                : questions.map((question, i) => (
                    <div className="col-xs-12 col-md-12" key={i}>
                      <SingleQuestion
                        ind={i}
                        {...question}
                        key={i}
                        id={i + 1}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordian1;
