import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import "../ContactUs/contactus.css";
import InputField from "../Input/InputField";
import useValidationFunctions from "../Utils/ValidationFunctions";
import phone from "../Assets/images/services/contactUs/phone_2x.webp";
import mail from "../Assets/images/services/contactUs/mail_2x.webp";
import locationImg from "../Assets/images/services/contactUs/map_pin_2x.webp";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const ContactUs = () => {
  const { errors, validateEmail, validatePhone } = useValidationFunctions();
  const [values, setValues] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const [emailShow, setEmailShow] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [required, setRequired] = useState({
    nameRequired: false,
    emailRequired: false,
    numberRequired: false,
  });
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= 768 ? true : false
  );

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const location = useLocation();

  useEffect(() => {
    let findElement = document?.getElementById(
      location?.state?.data
    )?.offsetTop;
    scrollToSpecificElement(findElement);
  }, [location.state]);
  const scrollToSpecificElement = (top) => {
    if (top) {
      window.scrollTo({
        top: top,
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  const updateDimensions = () => {
    setIsDesktop(window.innerWidth >= 768 ? true : false);
  };

  const handleFields = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value,
    });
    setRequired(
      Object.keys(required).map(
        (item) => item.includes(e.target.name) && { ...required, item: false }
      )
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      name: e.target[0].value,
      email: e.target[3].value,
      number: e.target[6].value,
      message: e.target[9].value,
    };

    if (!values.name) {
      setRequired({
        ...required,
        nameRequired: true,
      });
      return;
    }
    if (!values.email) {
      setRequired({
        ...required,
        emailRequired: true,
      });
      return;
    }
    if (!values.number) {
      setRequired({
        ...required,
        numberRequired: true,
      });
      return;
    }

    fetch(
      `https://3d255ebio4kpkybsa6bs77bjze0olsvz.lambda-url.ap-southeast-1.on.aws?&name=${data.name}&number=${data.number}&message=${data.message}&email=${data.email}`
    )
      .then((response) => response.json())
      .then((data) => {
        setValues({ name: "", email: "", number: "", message: "" });
        setEmailFailed(false);
        setEmailShow(true);
        setTimeout(() => {
          setEmailShow(false);
        }, 2000);
      })
      .catch((error) => {
        setEmailFailed(true);
        setEmailShow(true);
        setTimeout(() => {
          setEmailShow(false);
        }, 2000);
      });
  };
  return (
    <>
      <div className="contact_us">
        <main className=" contactUs_main" id="01">
          <div className="contactUs_des">
            <div className="need_help_div">
              <p className="need_help_heading">Need Help? </p>
            </div>
            <div className="des_div">
              <p className="des_para">
                Our surgeons are always available to help patients. Whether you
                are looking for medical advice or have any concerns related to
                surgery, contact us.{" "}
              </p>
            </div>
          </div>
          <div className="contactUs_form">
            <form onSubmit={handleSubmit}>
              <Grid>
                <InputField
                  label="Name*"
                  name="name"
                  onChange={handleFields}
                  value={values.name}
                  height="18px"
                />
                {required.nameRequired && (
                  <div className="text-danger">Required</div>
                )}
              </Grid>
              <Grid>
                <InputField
                  label="Email*"
                  onKeyPress={validateEmail}
                  name="email"
                  value={values.email}
                  onChange={handleFields}
                  height="18px"
                />
                {required.emailRequired && (
                  <div className="text-danger">Required</div>
                )}
                <div className="text-danger">{errors.email}</div>
              </Grid>
              <Grid>
                <InputField
                  label="Phone Number*"
                  value={values.number}
                  height="18px"
                  type="number"
                  onKeyPress={validatePhone}
                  name="number"
                  onChange={handleFields}
                />
                {required.numberRequired && (
                  <div className="text-danger">Required</div>
                )}
                <div className="text-danger">{errors.phone}</div>
              </Grid>
              <div className="message_height">
                <Grid>
                  <InputField
                    label="Message*"
                    value={values.message}
                    name="message"
                    onChange={handleFields}
                    multiline
                    rows={4}
                    height={"184px"}
                  />
                </Grid>
              </div>
              <Grid sx={{ float: "right" }} item xs={12} sm={6}>
                <button className="contact_btn">Submit</button>
              </Grid>
            </form>
          </div>{" "}
          <div className="contactUs_methods d-flex flex-column methods_web_div d-md-flex d-none">
            <div className="d-flex align-items-center method_div">
              {" "}
              <img className="contactUs_imgs" src={phone} />{" "}
              <a className="contactUs_anchor" href="tel: +92 325 9400555">
                {" "}
                +92 325 9400555{" "}
              </a>{" "}
              <span className="contactUs_anchor">/</span>{" "}
              <a className="contactUs_anchor" href="tel: +92 326 9400555">
                +92 326 9400555
              </a>
            </div>
            <div className="d-flex align-items-center method_div">
              {" "}
              <img className="contactUs_imgs" src={locationImg} />{" "}
              <a
                className="contactUs_anchor"
                href="https://goo.gl/maps/qQnetCzocH7NUyqM7"
                target="_blank"
              >
                153/1, F-Block, Phase-5 DHA Lahore – Pakistan
              </a>
            </div>
            <div className="d-flex align-items-center method_div">
              {" "}
              <img className="contactUs_imgs" src={mail} />{" "}
              <a
                className="contactUs_anchor"
                href="mailto:help@advancesurgicare.com"
              >
                Help@advancesurgicare.com
              </a>
            </div>
          </div>
        </main>
        <div className="contactUs_mob">
          <main className="contactUs_mob_main d-md-none d-block">
            <div className="contactUs_methods d-flex flex-column methods_mob_div d-md-none d-flex">
              <div className="d-flex align-items-center method_div">
                {" "}
                <img className="contactUs_imgs" src={phone} />{" "}
                <a className="contactUs_anchor" href="tel: +92 325 9400555 ">
                  {" "}
                  +92 325 9400555{" "}
                </a>{" "}
                <span className="contactUs_anchor">/</span>{" "}
                <a className="contactUs_anchor" href="tel: +92 326 9400555">
                  +92 326 9400555
                </a>
              </div>
              <div className="d-flex align-items-center method_div">
                {" "}
                <img className="contactUs_imgs" src={locationImg} />{" "}
                <a
                  className="contactUs_anchor"
                  href="https://goo.gl/maps/qQnetCzocH7NUyqM7"
                  target="_blank"
                >
                  153/1, F-Block, Phase-5 DHA Lahore – Pakistan
                </a>
              </div>
              <div className="d-flex align-items-center method_div">
                {" "}
                <img className="contactUs_imgs" src={mail} />{" "}
                <a
                  className="contactUs_anchor"
                  href="mailto:help@advancesurgicare.com"
                >
                  Help@advancesurgicare.com
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Modal show={emailShow}>
        <Modal.Body>
          {!emailFailed ? (
            <p className="m-0 text-center">
              Thank you for reaching out to us at Advance Surgicare. We will
              respond to you as soon as possible.
            </p>
          ) : (
            <p className="m-0 text-center">
              Something went wrong! Please try again
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactUs;
