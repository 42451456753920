import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainLayout from "./component/Layouts/MainLayout";
import { Routes } from "./Routes";
import ScrollToTop from "./component/Layouts/ScrollToTop";
import ReactPixel from "react-facebook-pixel";
ReactPixel.init("761158152271147");

function App() {
  useEffect(() => {
    ReactPixel.pageView();
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <MainLayout>
          <Switch>
            {Routes.map((prop, key) => {
              return (
                <Route
                  exact
                  path={prop.path}
                  key={key}
                  render={() => prop.component}
                />
              );
            })}
          </Switch>
        </MainLayout>
      </BrowserRouter>
    </>
  );
}

export default App;
