import Laparoscopicimage1 from "../Assets/images/services/LayoutImages/why_undergo_section_2x.webp";
import Laparoscopicimage2 from "../Assets/images/services/LayoutImages/patient_centered_section_2x.webp";
import Laparoscopicimage3 from "../Assets/images/services/LayoutImages/special_conditions_paragraph_2x.webp";
import bariatric1 from "../Assets/images/services/LayoutImages/laparoscopic_bariatric_surgery_2_2x.webp";
import bariatric2 from "../Assets/images/services/LayoutImages/patient_centered_2x.webp";
import bariatric3 from "../Assets/images/services/LayoutImages/why_consider_2x.webp";
import Endocrine1 from "../Assets/images/services/LayoutImages/encodrine_surgery_focused_on_you_section_1_2x.webp";
import Endocrine2 from "../Assets/images/services/LayoutImages/pancreatic_diseases_section_1_2x.webp";
import Oncology1 from "../Assets/images/services/LayoutImages/sop_followed_section_1_2x.webp";
import Oncology2 from "../Assets/images/services/LayoutImages/cancer_patients_section_1_2x.webp";
import Oncology3 from "../Assets/images/services/LayoutImages/cancer_screening_section_1_2x.webp";
import Vascular1 from "../Assets/images/services/LayoutImages/peripheral_vascular_surgery_4_2x.webp";
import VascularChange from "../Assets/images/services/LayoutImages/diagnosed_timely_section_1_2x.webp";
import Vascular2 from "../Assets/images/services/LayoutImages/surgical_care_for_vacular_health_section_1_2x.webp";
import Health1 from "../Assets/images/services/LayoutImages/pelvic_surgeries_section_1_2x.webp";
import Health2 from "../Assets/images/services/LayoutImages/exceptional_breast_care_section_1_2x.webp";
import Health3 from "../Assets/images/services/LayoutImages/diagnose_breast_cancer_section_1_2x.webp";
import HealthfloatingImg from "../Assets/images/services/LayoutImages/we_care_for_you_4x.webp";
import BarDiabetes from "../Assets/images/services/LayoutGifs/Bariatric---Diabetes.gif";
import BarCancer from "../Assets/images/services/LayoutGifs/Bariatric---Cancer.gif";
import BarHeartDisease from "../Assets/images/services/LayoutGifs/Bariatric---Heart-Diseases.gif";
import BarHypertension from "../Assets/images/services/LayoutGifs/Bariatric---Hypertension.gif";
import BarInfertility from "../Assets/images/services/LayoutGifs/Bariatric---Infertility.gif";
import BarJointPain from "../Assets/images/services/LayoutGifs/Bariatric---Joint-Pain.gif";
import BarHealthRisk from "../Assets/images/services/LayoutGifs/Bariatric---Other-Health-Risks.gif";
import BarSleepApnea from "../Assets/images/services/LayoutGifs/Bariatric---Sleep-Apnea.gif";
import BarLifeSpan from "../Assets/images/services/LayoutGifs/Baritric---Decreases-Life-Span.gif";
import EndoParathyroid from "../Assets/images/services/LayoutGifs/Endocrine---Parathyroid-Surgery.gif";
import EndoThyroid from "../Assets/images/services/LayoutGifs/Endocrine - Thyroid-surgery.gif";
import EndoAdrenal from "../Assets/images/services/LayoutGifs/Endorine - Laparoscopic-Adrenal-Surgery.gif";
import LapAppendix from "../Assets/images/services/LayoutGifs/Laparoscopic---Appendix-&-Intestinal-Surgery.gif";
import LapHepatobiliary from "../Assets/images/services/LayoutGifs/Laparoscopic---Hepatobiliary-Surgery.gif";
import LapHernia from "../Assets/images/services/LayoutGifs/Laparoscopic---Hernia-Surgery.gif";
import LapSolidOrgan from "../Assets/images/services/LayoutGifs/Laparoscopic-Solid-Organ-Surgery.gif";
import WomenBreastProblem from "../Assets/images/services/LayoutGifs/Women---Breast-Related-Problems.gif";
import WomenConservation from "../Assets/images/services/LayoutGifs/Womens---Breast-Conservation-Surgery.gif";
import WomenGynae from "../Assets/images/services/LayoutGifs/Womens---Laparoscopic-Gynaecological-Services.gif";
import WomenPelvicDisorders from "../Assets/images/services/LayoutGifs/Womens---Surgeries-For-Pelvic-Disorders-&-Piles-Clinic.gif";
import VascularArterial from "../Assets/images/services/LayoutGifs/Vascular - Arterial Diseases.gif";
import VascularVenous from "../Assets/images/services/LayoutGifs/Vascular - Venous-Disease.gif";
import CancerBreast from "../Assets/images/services/LayoutGifs/Cancer---Breast-Tumors.gif";
import CancerLower from "../Assets/images/services/LayoutGifs/Cancer---Lower-Abdominal-Cancers.gif";
import CancerUpper from "../Assets/images/services/LayoutGifs/Cancer---Upper-Abdominal-Cancers.gif";

export const servicesDataArray = {
  laparoscopic: {
    id: 1,
    searchpath: "1",
    path: "laparoscopic",
    BannerTitle: "Advance Laparoscopic Surgery",
    suggestion: `Advance Laparoscopic  <span class="suggestion_text"> Surger</span>y`,

    BannerSubTitle: `Premier laparoscopic healthcare facility <span class="yellowText">providing comprehensive surgical treatment.</span>`,
    BannerImage: "LaparoscopicBannerImage",
    DivsTitle: "Our Laparoscopic Surgical Approach",
    DivsSubTitle:
      "Laparoscopy is all about advancement in surgical procedures that we offer in the very best of setups at Advance Surgicare. No one likes having huge scars on their body post-surgery and risk of developing complications like wound infections and hernias. In that case, laparoscopy is the answer. Hence, we have established the highest quality of standards to provide minimally-invasive surgical care to our patients and improve their quality of life.",
    DivsImage: "LaparoscopicdivImage",
    Detailimage1: Laparoscopicimage1,
    Detailimage2: Laparoscopicimage2,
    Detailimage3: Laparoscopicimage3,
    Arr1Title: "Surgeries We Perform Laparoscopically",
    buttonPhoneNumber: "+923229400555",
    phonenumber: "123",
    Arr1SubTitle:
      "We use the advanced laparoscopic approach to perform many common surgeries that most surgeons perform via open surgery. It is one of our most preferred methods for treating many health problems in men and women because of the improved patient outcomes. Surgeries that are performed laparoscopically at Advance Surgicare include:",
    DesktopData:
      "Laparoscopy is the art of viewing the body cavities, mainly the thorax and abdomen, without opening them up by making huge incisions. Surgeries are performed laparoscopically at Advance Surgicare by making small puncture wounds, termed ports. We prefer this surgical approach over open surgery because of the following reasons:",
    firstArr: {
      first: [
        {
          title: "Laparoscopic Hepatobiliary Surgery",
          image: LapHepatobiliary,
          bulletsArr: [
            "Gall Stones",
            "Duct Stones",
            "Pancreatic Cancer",
            "Gall Bladder Cancer",
            "Liver Cancer",
            "Liver Cysts",
          ],
        },
        {
          title: "Laparoscopic Hernia Surgery",
          image: LapHernia,
          bulletsArr: [
            "Inguinal Hernia (Groin Hernia)",
            "Belly Button Hernia (Umbilical Hernia)",
            "Hiatal Hernia (For Acid Reflux)",
            "Internal Hernias (For Intestinal Obstruction)",
            "Incisional Hernia (Post Open Surgery Hernia)",
            "Diaphragmatic Hernia (For Breathing Problems)",
          ],
        },
      ],
      second: [
        {
          title: "Laparoscopic Appendix & Intestinal Surgery",
          image: LapAppendix,
          bulletsArr: [
            "Appendicectomy (Simple & Complicated)",
            "Ulcer Perforation",
            "Small Bowel Obstruction",
            "Large Bowel Cancer",
            "Large Bowel Ulcers & Polyps",
            "Rectal Prolapse & Bleeding",
          ],
        },
        {
          title: "Laparoscopic Solid Organ Surgery",
          image: LapSolidOrgan,
          bulletsArr: [
            "Uterus & Ovaries",
            "Adrenal Glands",
            "Spleen",
            "Kidney Tumors",
            "Liver Tumors & Cysts",
            "Pancreatic Stones & Tumors",
          ],
        },
      ],
    },
    Array1: [
      {
        id: 1,
        Title: "Hepatobiliary (Gall Bladder)",
        readMore: true,
        SubTitle:
          "Diseases of the gall bladder are treated laparoscopically at Advance Surgicare. They can be caused by toxic chemicals, metabolic disorders, parasitic, viral, or bacterial infections, neoplasia, and also cardiac failure. No matter what the cause is, laparoscopic hepatobiliary surgery is the advanced treatment option - which is performed by our highly specialized team of surgeons. They work together to diagnose and treat biliary system conditions.",
      },
      {
        id: 2,
        Title: "Laparoscopic Hernia ",
        readMore: true,
        SubTitle:
          "A hernia occurs when the fatty tissues or intestines bulge through a weak area or opening in the connective tissues or surrounding muscles, such as the abdomen. Often, this happens when a form of pressure causes breaking in the tissues that are weak. The surgeons at Advance Surgicare are highly trained in diagnosing and repairing hernia laparoscopically.",
      },
      {
        id: 3,
        Title: "Appendix Surgery",
        readMore: true,
        SubTitle:
          "An appendix is a thin tube joined with the large intestine. It can get infected and if left untreated, the appendix can rupture because of being blocked. Appendicitis can also be caused by different infections, like parasites, bacteria, or viruses in the digestive tract. For removing an appendix before it bursts and leaks through into the belly of the patient, we perform a laparoscopic appendectomy.",
      },
      {
        id: 4,
        Title: "Intestinal Surgery",
        readMore: true,
        SubTitle:
          "Surgeries performed to treat any condition in the intestines are referred to as intestinal surgeries. Although they can be performed both ways - conventional open surgery and laparoscopically, at Advance Surgicare, we prefer the minimally invasive laparoscopic treatment option. It involves making a small incision in the intestines, and repairs are done with a laparoscope, leaving behind no scars and normally functioning intestines. ",
      },
      {
        id: 5,
        Title: "Solid Organ Surgery ",
        readMore: true,
        SubTitle:
          "Solid organs are those organs in the abdominal area that perform multiple functions and have a solid consistency. Examples of solid organs are the uterus, ovaries, pancreas, adrenal glands, kidneys, spleen, and liver. Laparoscopic surgery is preferred over open surgeries when it comes to treating conditions in solid organs. By working through minute incisions, surgeons at Advance Surgicare perform surgical treatments, after which the patients recover faster. ",
      },
      {
        id: 6,
        Title: "Oncology Surgery ",
        readMore: true,
        SubTitle:
          "Laparoscopic surgery is used for treating the cancerous cells in the gall bladder, breast, liver, pancreas, thyroid, spleen, and appendix. The benefit of this approach is that small incision are made, less blood is lost, and the patient recovers quicker. Advance Surgicare has one of the most comprehensive laparoscopic facilities in Pakistan, and our surgeons make sure that surgical outcomes are improved. ",
      },
      {
        id: 7,
        Title: "Vascular Surgery ",
        readMore: true,
        SubTitle:
          "Vascular diseases can keep a person from living their life to the fullest. Whether caused because of injury, genetics, varicose veins, or any other condition like heart disease or diabetes, the vascular disease leads to serious health complications. The surgical staff at Advance Surgicare specializes in treating vascular diseases using the latest minimally invasive surgical technique - laparoscopy, which promotes quick recovery and less scarring.",
      },
      {
        id: 8,
        Title: "Pelvic Surgery ",
        readMore: true,
        SubTitle:
          "Laparoscopy is a surgery that is done by making small incisions. By using the laparoscope - a long tool with a light and camera, the surgeons see inside the pelvic region, diagnose the condition and then treat it. Mostly, pelvic surgery is performed by us laparoscopically to treat problems like infertility, severe pain, or any other problem that is diagnosed.",
      },
      {
        id: 9,
        Title: "Bariatric Surgery",
        readMore: true,
        SubTitle:
          "Obesity always brings many health problems, and for people who are obese, losing at least some of their excess body weight is crucial. Surgical treatment of obesity is the only option to help them get rid of that weight and reach an ideal Body Mass Index. The bariatric surgeons at Advance Surgicare are experts at performing bariatric surgeries in a minimally-invasive way.",
      },
    ],

    // // grayBg: grayBg,
    // // upperBg: upperBg,
    floatingImg: HealthfloatingImg,
    lastPara:
      "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    lastTitle: "We Care For YOU",
    lastBulletArray: [
      "Highly-Experienced Surgeons",
      "Availability Of Female Surgeons",
      "Patient-Focused Care",
      "Comprehensive Diagnosis & Treatment",
      "Dedicated Post-Surgery Follow-Up",
      "Affability & Alacrity",
    ],

    Arr2Button: "Call Us On WhatsApp",
    Arr2Title: "Why Undergo Laparoscopic Surgery?",
    Arr2SubTitle:
      "Laparoscopy is the art of viewing the body cavities, mainly the thorax and abdomen, without opening them up by making huge incisions. Surgeries are performed laparoscopically at Advance Surgicare by making small puncture wounds, termed ports. We prefer this surgical approach over open surgery because of the following reasons:",

    listView2: false,
    listView3: false,
    listView1: true,
    Array2: [
      {
        Title: "Better Cosmetic Results",
      },
      {
        Title: "Small Incisions",
      },
      {
        Title: "Less Post-Operative Pain",
      },
      {
        Title: "No Huge Wounds",
      },
      {
        Title: "Short Hospital Stay ",
      },
      {
        Title: "No Hernias",
      },
      {
        Title: "Quick Return To Normal Activity",
      },
      {
        Title: "Less Risk Of Complications",
      },
      {
        Title: "Better Post-Surgery Body Image",
      },
    ],

    FlexButton: "Call Now For Consultation",
    // FlexImage: Laparoscopicimage3,
    FlexTitle: "Patient-Centered Laparoscopic Surgical Care",
    FlexSubTitle:
      "One of the most pertinent question that every patient asks is - why choose Advance Surgicare for undergoing laparoscopic surgery? The answer to this is simple. At Advance Surgicare, we have a state-of-the-art operation theatre setup with modern technology and gadgetry at our disposal that makes laparoscopy a success story for every surgical procedure; minor and major. Our anesthesia team in the ICU is also capable of caring for critically ill patients after major laparoscopic surgical interventions. Most importantly, our surgical expertise is rich in competence and experience, which makes it the backbone of our surgical services. Moreover, around the clock 24/7 availability of a consultant surgeon makes it a unique surgical facility.",
    Arr3Button: "Call Now For Consultation",
    Arr3Title: "What Special Conditions Merit Laparoscopic Approach?",
    Arr3SubTitle:
      "Laparoscopy is a surgical procedure that allows surgeons to access a body organ without making any large incisions. The surgeons at Advance Surgicare use the approach to perform surgeries on cancer and immune-compromised patients who cannot undergo traditional surgeries that involve making huge incisions. Moreover, this approach addresses the major cosmetic concern of patients by not leaving huge post-surgery scars on the body.",
    Array3: [
      {
        Title: "Resection Surgery",
        SubTitle:
          "Laparoscopic surgery is performed on cancer patients who are frail and cannot undergo traditional surgery that involves making huge incisions. ",
      },
      {
        Title: "Bariatric Surgery ",
        SubTitle:
          "Another surgery that we specifically perform is bariatric surgery on morbidly obese people. Larger incisions can lead to more complications, and laparoscopy helps in avoiding them.",
      },
    ],
    FAQTitle:
      "Have any questions related to Advance Laparoscopic Surgery? We’ve got the answers! ",
    FAQArray: [
      {
        Title: "Are There Any Risks Of Laparoscopic Surgery?",
        SubTitle:
          "The risks involved in laparoscopic surgery are less than those in open surgery. However, there can be a possibility of a complication during the surgery, resulting in the surgeon having to make a large incision. In that case too, the unforeseen risks are small and manageable",
      },
      {
        Title: "What Is The Normal Recovery Time Post Laparoscopic Surgery? ",
        SubTitle:
          "Usually, the recovery time is based on the surgically treated area and the patient's case. Most of the time, patients have to take pain medication for a week only post their laparoscopic surgery. We even advise returning to work after just a week or two, provided that no physically strenuous activity is involved.",
      },
      {
        Title: "Can I Undergo Multiple Procedures At The Same Time?",
        SubTitle:
          "Yes, multiple surgical procedures can be performed laparoscopically at the same time, especially procedures performed on the gall bladder and hepatobiliary channels.",
      },
      {
        Title: "Are Laparoscopic Procedures Also Performed On Pregnant Women? ",
        SubTitle:
          "Yes, laparoscopic procedures are also used to perform surgeries on pregnant women, mostly for common indications like biliary diseases, appendicitis, and adnexal masses during pregnancy. Utmost care is taken to preserve the pregnancy while performing the surgery.",
      },
      {
        Title: "Am I A Good Candidate For Laparoscopic Surgery?",
        SubTitle:
          "Any adult who is eligible to undergo abdominal surgery can be a candidate for laparoscopic surgery, given that it guarantees quick recovery post-surgery and does not leave huge scars on the body. ",
      },
      {
        Title: "Are There Any Pre-Surgery Instructions That I Have To Follow? ",
        SubTitle:
          "Yes, patients who are to undergo laparoscopic surgery are instructed to abstain from food intake after midnight before their surgery. In case of some other health problems, patients are advised to consult a concerned specialist, such as a pulmonologist or cardiologist. ",
      },
    ],
  },
  bariatric: {
    id: 2,
    path: "bariatric",
    BannerTitle: "Bariatric (Weight Loss) Surgery",
    suggestion: `Bariatric (Weight Loss)  <span class="suggestion_text"> Surger</span>y`,
    BannerSubTitle: `Surgical care specifically for <span class="yellowText">severely obese patients who want to live a healthy life.</span>`,
    BannerImage: "BariatricBannerImage",

    DivsTitle: "Treat & Stop Uncontrollable Obesity",
    DivsSubTitle:
      "Obesity is a chronic disease that involves the accumulation of excess body fat, sufficient to harm overall health. People whose Body Mass Index (BMI) is 30 (South Asian 25 or more) are considered obese. In case the BMI increases to 35 (South Asian 30 or more), it is termed Morbid Obesity, which is life-threatening. To treat uncontrollable obesity, we offer minimally invasive bariatric surgeries laparoscopically/ endoscopically. ",
    DivsImage: "BariatricdivImage",
    Detailimage1: bariatric1,
    Detailimage2: bariatric2,
    Detailimage3: bariatric3,
    Arr1Title: "Health Risks Of Obesity",
    buttonPhoneNumber: "+923219400555",
    phonenumber: "1234",
    Arr1SubTitle:
      "Many health problems are associated with obesity. In fact, the risks associated with the conditions are even more than the surgical treatment itself.",
    DesktopData:
      "Most surgeons perform bariatric surgery through the conventional open method, which leaves huge incisions and scars on the patient's body. However, at Advance Surigcare, bariatric surgery is done laparoscopically by making small incisions. Our laparoscopic approach offers the following benefits over the conventional operative procedure:",
    firstArr: {
      first: [
        {
          title: "Diabetes",
          image: BarDiabetes,
          bulletsArr: [
            "High Association With Obesity",
            "5-8 Kg Weight Gain Doubles Type 2 Diabetes Risk",
            "Bariatric Surgery Cures The Symptoms of Diabetes",
            "Patient Can Get Rid Of Diabetic Medication Including Insulin",
          ],
        },
        {
          title: "Heart Diseases",
          image: BarHeartDisease,
          bulletsArr: [
            "High Risk Of Heart Attacks",
            "Sudden Cardiac Death In Worst Cases",
            "Early Closure Of Coronary/Heart Arteries",
            "Solution To Prevent Heart Diseases Is Curing Obesity",
          ],
        },
      ],
      second: [
        {
          title: "Hypertension",
          image: BarHypertension,
          bulletsArr: [
            "High Blood Pressure Is a Risk Factor Of Obesity",
            "Obesity Leads To Narrowing Of Arteries",
            "Narrowing Leads To High Blood Pressure",
            "Bariatric Surgery transfers the fat from arteries to the liver for metabolism, thus opening the arteries.",
          ],
        },
        {
          title: "Infertility",
          image: BarInfertility,
          bulletsArr: [
            "Obese women often face difficulty in getting pregnant",
            "Body fat affects the normal estrogen level",
            "To enhance the chances of getting pregnant, they need to lose weight.",
            "So, bariatric surgery improves the fertility/ pregnancy prospects",
          ],
        },
      ],
      third: [
        {
          title: "Sleep Apnea",
          image: BarSleepApnea,
          bulletsArr: [
            "Breathing problems while sleeping is a common complication in overweight people.",
            "Fat deposition narrows the respiratory passages and weakens the breathing muscles.",
            "Very high risk of death dying while sleeping due to hypoxic (less oxygen) damage to the heart and lungs.",
            "Weight loss surgery is the definite cure/answer",
          ],
        },
        {
          title: "Cancer",
          image: BarCancer,
          bulletsArr: [
            "Following cancers are related to being overweight (increased risk)",
            "Cancer of the uterus, gallbladder, colon, prostate, breast, and kidney are the ones.",
            "BMI of less than 35 (30 in the South Asian population) can lower the risk",
            "The leaner the person, the lower the risk of getting cancer",
          ],
        },
      ],
      fourth: [
        {
          title: "Joint Pain",
          image: BarJointPain,
          bulletsArr: [
            "Being overweight gives you joint pains",
            "It decreases your mobility and negatively impacts the quality of life",
            "1 KG increase in weight, increases the risk of arthritis from 9-13%",
            "So weight loss surgery is the only solution even when you are planning for knee joint replacement surgery",
          ],
        },
        {
          title: "Decreases Life Span",
          image: BarLifeSpan,
          bulletsArr: [
            "Obese people have decreased lifespan",
            "Being overweight is associated with early death",
            "40-year old non-smoker females lose 7.1 years of their life",
            "40-year old non-smoker males lose 5.8 years of their life",
          ],
        },
      ],
      fifth: [
        {
          title: "Other Health Risks",
          image: BarHealthRisk,
          bulletsArr: [
            "Other risks associated with obesity are as follows",
            "Heartburn, loss of urinary control, and early cancer of the oesophagus.",
            "Venous problem in the legs",
            "Backbone disc disease, resulting in chronic lower back pain.",
          ],
        },
      ],
    },
    Array1: [
      {
        id: 1,
        readMore: true,

        Title: "High Blood Sugar/ Diabetes",
        SubTitle:
          "Diabetes that is not dependent on insulin has a high association with obesity. A 5 to 8 kg gain in weight can increase your risk of getting type 2 diabetes; twice that of a person who has not gained any weight. After bariatric surgery, patients no longer show symptoms of being diabetic nor have to intake medication for diabetes.",
      },
      {
        id: 2,
        readMore: true,

        Title: "Heart Diseases",
        SubTitle:
          "Obese people are at a high risk of suffering from heart diseases, such as angina or severe chest pain, heart attack, abnormal heart rhythms, congestive heart failure, and even sudden death in the worst cases. The only clinical solution to prevent these heart diseases/conditions caused by obesity is undergoing laparoscopic bariatric surgery that will leave no scar on the body and reduce your weight.",
      },
      {
        id: 3,
        readMore: true,

        Title: "High Blood Pressure/ Hypertension",
        SubTitle:
          "Hypertension or high blood pressure is also a risk factor that comes with obesity. The primary recommendation of surgeons at Advance Surgicare to people with high blood pressure is to reduce their weight, preferably through bariatric surgery, if they have a BMI over 35 and are obese. We perform the surgery laparoscopically by making minute incisions to ensure that the cosmetic results are better.",
      },
      {
        id: 4,
        readMore: true,

        Title: "Infertility/ Pregnancy Complications",
        SubTitle:
          "Severely obese women are often infertile because the fatty tissues in the body affect their normal estrogen hormone levels. To increase the chances of getting pregnant and delivering without any complications, obese women have to undergo laparoscopic bariatric surgery. If not treated before pregnancy, obesity can complicate delivery and result in the death of the mother or baby during labor.",
      },
      {
        id: 5,
        readMore: true,

        Title: "Sleep Apnea ",
        SubTitle:
          "Gaps in breathing while sleeping is a serious and common complication caused by obesity. Fats deposited in the respiratory tract of obese people narrow their airways, leading to less muscle activity in the region, ultimately resulting in sleep apnea. It is such a serious health risk that an obese person can even die suddenly because of the lungs or heart getting damaged during sleeping.",
      },
      {
        id: 6,
        readMore: true,

        Title: "Cancer",
        SubTitle:
          "Obesity is also associated with an increased risk of developing cancerous tumors in the uterine, gall bladder, colon, prostate, breast, and kidney. Even though many factors can cause cancer, maintaining a healthy weight and BMI of less than 35 by undergoing laparoscopic bariatric is one of the most important steps an obese person can take to lower the risk of getting cancer.",
      },
      {
        id: 7,
        readMore: true,

        Title: "Arthritis/ Joint Pain",
        SubTitle:
          "A significant effect of obesity on the musculoskeletal system is arthritis or joint pain. It is a joint disorder described by decreased mobility and severe joint pain, which negatively impact the quality of life. For every 1 kg increase in weight, the risk of developing obesity-related arthritis increases from 9% to 13%, and the only solution to control obesity is bariatric surgery.",
      },
      {
        id: 8,
        readMore: true,

        Title: "Decreased Life Span",
        SubTitle:
          "People who are medically declared severely obese have a shorter life span and are recommended bariatric surgery to improve their quality of life. Medical study has declared that overweight and obesity are highly associated with an increase in early death. While 40-year-old non-smoker females lose 7.1 years of their life due to obesity, 40-year-old non-smoker obese males lose 5.8 years of their life.",
      },
      {
        id: 9,
        readMore: true,

        Title: "Other Health Risks ",
        SubTitle:
          "Severe heartburn, urinary incontinence, gastroesophageal reflux, venous problems in the legs, disk diseases, disability from degenerative arthritis, and lower back pain are also associated with being obese. Other health problems, including hypoventilation, joint pain, and shortness of breath, can be reversed or improved by bariatric surgery. We perform the surgery in a minimally-invasive way that leaves no scars on the patient's body.",
      },
    ],

    // // grayBg: grayBg,
    // // upperBg: upperBg,
    floatingImg: HealthfloatingImg,
    lastPara:
      "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    lastTitle: "We Care For YOU",
    lastBulletArray: [
      "Highly-Experienced Surgeons",
      "Availability Of Female Surgeons",
      "Patient-Focused Care",
      "Comprehensive Diagnosis & Treatment",
      "Dedicated Post-Surgery Follow-Up",
      "Affability & Alacrity",
    ],

    Arr2Button: "Contact Us On WhatsApp",
    Arr2Title: "Why Do We Perform The Weight Loss Surgeries Laparoscopically?",
    Arr2SubTitle:
      "Most surgeons perform bariatric surgery through the conventional open method, which leaves huge incisions and scars on the patient's body. However, at Advance Surigcare, bariatric surgery is done laparoscopically by making small incisions. Our laparoscopic approach offers the following benefits over the conventional operative procedure:",
    listView2: false,
    listView3: true,
    listView1: true,
    Array2: [
      {
        Title: "Less Post-Surgery Pain",
      },
      {
        Title: "Better Cosmetic Results",
      },
      {
        Title: "Low Wound Infection Risk",
      },
      {
        Title: "Early Return To Routine",
      },
      {
        Title: "No Huge Incisions/ No Abdominal Hernias",
      },
      {
        Title: "Short Hospital Stay",
      },
    ],

    FlexButton: "Call Now For Consultation",
    // FlexImage: Bariatricfleximage,
    FlexTitle: "Patient-Centered Approach To Bariatric Surgery",
    FlexSubTitle:
      "The bariatric surgeries performed by surgeons at Advance Surgicare rely on two primary techniques that promote weight loss - intestinal malabsorption and gastric restriction. Hormonal changes are also an important post-surgery mechanism for weight loss. The alterations in the release of hormones causing hunger results in reduced hunger, early satiety, and other major health benefits.",
    Arr3SubTitle:
      "People who are severely or morbidly obese are at high risk of major health problems and tend to live for a short life span. There are also other potential risks associated with a continuous increase in weight. Bariatric surgery has become an accepted clinical method to ensure long- term maintained weight loss in obese patients. The following persons are eligible for laparoscopic bariatric surgery: ",
    Arr3Button: "Call Now For Consultation",
    Arr3Title: "Why Consider The Bariatric Surgery Option?",
    Array3: [
      {
        Title: "45 Kg Above Ideal Body Weight",
        SubTitle: "",
      },
      {
        Title: "36 Kg Above Ideal Body Weight & Diabetic",
        SubTitle: "",
      },
      {
        Title: "36 Kg Above Ideal Body Weight & Hypertensive",
        SubTitle: "",
      },
      {
        Title: "30 Kg Above Ideal Body Weight Having Sleep Apnea",
        SubTitle: "",
      },
    ],
    FAQTitle:
      "Have questions and concerns related to Bariatric Surgery? We’ve got the answers! ",
    FAQArray: [
      {
        Title: "What To Expect When I First Visit The Surgeon?",
        SubTitle:
          "By the time you will meet our surgeon, preliminary testing of your potential obesity-related problems would already be complete. The surgeon will review your medical and dietary history, after which the concerns will be discussed. ",
      },
      {
        Title: "When Is A Bariatric Surgery Considered Successful?",
        SubTitle:
          "We consider a bariatric surgery successful when the obese patient loses 50% of the excess body weight and sustains the loss for 5 years. In fact, all of our patients lose one-half to two-thirds of their body weight after the surgery.",
      },
      {
        Title: "Are There Any Restrictions Post-Surgery?",
        SubTitle:
          "Yes, during the post-surgery period, when the patient is taking pain medications, we restrict them from driving. Moreover, depending on how well the patient is recovering, we may or may not restrict them from weight lifting. Certainly, these restrictions are for two weeks only, and later the patients can live normally. ",
      },
      {
        Title: "Can I Eat Anything Post-Surgery Or Limit Fat & Sugar Intake?",
        SubTitle:
          "You can eat anything and everything you wish to eat but in a reduced quantity so that you do not put on excess weight once again.",
      },
      {
        Title: "Is Exercising Post-Surgery Important?",
        SubTitle:
          "Patients should start walking as soon as possible post-surgery and exercise every day. Whether one has undergone the surgery or not, exercising is important for everyone. Hence, to maintain weight loss post- surgery, walk and exercise regularly.",
      },
      {
        Title: "How Often Will I Have To See The Doctor Post-Surgery?",
        SubTitle:
          "While a patient is recovering, we see them every few weeks to monitor recovery until 6 weeks post-surgery. After that, patients are required to see the doctor 6 months later and then 1 year later for a follow-up checkup.",
      },
      {
        Title: "Is There Anyone Who Cannot Undergo The Surgery?",
        SubTitle:
          "Patients suffering from schizophrenia, depression, and bipolar disease should consult a psychiatrist before they undergo the surgery, as their medical conditions can cause complications. However, weight loss surgery has shown to benefit the mental health conditions of the obese patients after weight loss.",
      },
      {
        Title: "When Can I Return To Normal Routine?",
        SubTitle:
          "Mostly, patients only remain in the hospital for a day or so and can resume their normal routine within 2-3 weeks post-surgery. Any pain after the surgery subsides within 10 days, and general fatigue only lasts for 2 to 3 weeks.",
      },
    ],
  },
  endocrine: {
    BannerTitle: "Endocrine Surgery",
    id: 3,
    path: "endocrine",
    suggestion: `Endocrine  <span class="suggestion_text"> Surger</span>y`,
    BannerSubTitle: `Exceptional surgical care for early <span class="yellowText">diagnosis and treatment of endocrine disorders.</span>`,
    BannerImage: "EndocrineBannerImage",

    DivsTitle: "Endocrine Care At Advance Surgicare",
    DivsSubTitle:
      "We are a one-stop clinic that treats hormonal and endocrine-related diseases in both - males and females. Our surgical care involves diagnosis, hormonal therapy, and definitive surgical treatment of endocrine diseases by a dedicated, experienced, and highly specialized surgical team. The highlight of our services is the availability of therapeutic, diagnostic, and surgical modalities, all under one roof to facilitate early diagnosis and prompt treatment.",
    DivsImage: "EndocrinedivImage",
    Detailimage1: Endocrine1,
    Detailimage2: Endocrine2,
    Arr1Title: "Effective Endocrine Surgeries We Perform",
    buttonPhoneNumber: "+923369400555",
    phonenumber: "1234",
    Arr1SubTitle:
      "Endocrine surgeons at Advance Surgicare have expertise in clinically specialized aspects of the surgery. Patients suffering from endocrine diseases are cared for by surgeons who improve their quality of life. They perform mainly three types of endocrine surgeries.",
    DesktopData:
      "Adrenal glands are located on the upper pole of each kidney and consist of two zones - the outer cortex and the inner medulla. The cortex is related to steroid hormone production while the medulla is responsible for secreting adrenaline and noradrenaline, the fight or flight hormones of our body regulating the cardiovascular system and blood pressure among other functions. Common diseases in the adrenal glands that we treat are:",
    firstArr: {
      first: [
        {
          title: "Thyroid Surgery",
          image: EndoThyroid,
          bulletsArr: [
            "Thyroid Nodules",
            "Thyroid Cancer",
            "Hyper Functioning of Thyroid (Thyrotoxicosis)",
            "Thyroglossal Cyst",
            "Endoscopic Thyroid Surgery (POET)",
            "Recurrent Thyroid Disorders",
          ],
        },
        {
          title: "Parathyroid Surgery",
          image: EndoParathyroid,
          bulletsArr: [
            "Hyper Functioning (Hyperparathyroidism)",
            "Bone Cysts",
            "Kidney Stones",
            "Stomach Ulcers",
            "Resistant Psychiatric Disorders",
            "Parathyroid Cancers",
          ],
        },
      ],
      second: [
        {
          title: "Laparoscopic Adrenal Surgery",
          image: EndoAdrenal,
          bulletsArr: [
            "Adrenal Tumors (Incidentaloma)",
            "Pheochromocytoma (Very High Blood Pressure)",
            "Cushing’s Syndrome (Lemon On Toothpick Appearance)",
            "Cancer Deposits In Adrenal Gland",
            "Hirsutism (Adrenal Malfunction)",
            "Sex Hormones Disorder (Adrenal Malfunction)",
          ],
        },
      ],
    },
    Array1: [
      {
        id: 1,
        readMore: true,

        Title: "Thyroid Surgery",
        SubTitle:
          "One of the major roles of thyroid glands is to produce thyroid hormones, which control the metabolic rate. Women are especially vulnerable to diseases in the thyroid glands. In case they have a family history of pernicious anemia, premature grey hair, Mellitus, insulin-dependent diabetes, or any other autoimmune diseases, the risk of thyroid malfunction is even higher. Thyroid surgery is performed depending on the specific thyroid malfunction being treated. Our surgeons treat the condition to the best possible extent, all while maintaining the patient's quality of life.Diseases of the gall bladder are treated laparoscopically at Advance Surgicare. They can be caused by toxic chemicals, metabolic disorders, parasitic, viral, or bacterial infections, neoplasia, and also cardiac failure. No matter what the cause is, laparoscopic hepatobiliary surgery is the advanced treatment option - which is performed by our highly specialized team of surgeons. They work together to diagnose and treat biliary system conditions.",
      },
      {
        id: 2,
        readMore: true,

        Title: "Parathyroid Surgery",
        SubTitle:
          "Four types of glands are located near the thyroid glands in the neck. These glands secrete parathyroid hormone (PTH), which regulates calcium levels in the body mainly by controlling the amount of calcium in the bones. Excess of PTH is caused by diseases like parathyroid adenoma, parathyroid hyperplasia, and parathyroid carcinoma, which causes bone pains, repeated fractures after minor injuries, kidney stones, and renal failure. Treatment options offered are Parathyroid Adenoma - surgical excision of the affected gland, Parathyroid Hyperplasia - surgical excision of all four parathyroid glands and Parathyroid Carcinoma.",
      },
      {
        id: 3,
        readMore: true,

        Title: "Peroral Endoscopic Myotomy (POEM)",
        SubTitle:
          "Peroral Endoscopic Myotomy (POEM) is a surgical procedure performed for treating swallowing problems in the muscles of the esophagus - a passageway between the stomach and the mouth. Mostly, the surgeons at Advance Surgicare consider POEM as a treatment option for patients suffering from a muscle disorder in their esophagus, like achalasia. The surgery is generally performed laparoscopically under anesthesia and only requires an overnight hospital stay.  It is 90% effective when it comes to relieving esophageal spasms and ensures fast recovery. ",
      },
    ],

    // // grayBg: grayBg,
    // // upperBg: upperBg,
    floatingImg: HealthfloatingImg,
    lastPara:
      "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    lastTitle: "We Care For YOU",
    lastBulletArray: [
      "Highly-Experienced Surgeons",
      "Availability Of Female Surgeons",
      "Patient-Focused Care",
      "Comprehensive Diagnosis & Treatment",
      "Dedicated Post-Surgery Follow-Up",
      "Affability & Alacrity",
    ],

    Arr2Button: "Contact Us On WhatsApp",
    Arr2Title: "Endocrine Surgeries Focused On YOU",
    Arr2SubTitle:
      "All the diagnostic and therapeutic modalities for the treatment of the above-mentioned adrenal gland diseases, parathyroid and thyroid malfunctions, including laparoscopic adrenalectomy, and endoscopic thyroidectomy (POET) are offered at Advance Surgicare, which is a blessing for the patients. It alleviates the need for opening the neck, and abdomen, or laparotomy as we call it, for surgical resection of the glands. Thus, avoiding large scars, hernias, and excessive pain after surgery, shortening the hospital stay, and allowing early return to normal life as a result.",
    listView2: true,
    listView3: false,
    listView1: false,
    Array2: [
      {
        Title: "Cushing's Syndrome In Adrenal Cortex ",
      },
      {
        Title: " ",
      },
      {
        Title: "",
      },
      {
        Title: "",
      },
      {
        Title: "Pheochromocytoma In Adrenal Medulla ",
      },
    ],

    FlexButton: "Call Now For Consultation",
    // FlexImage: Endocrinefleximage,
    FlexTitle: "What Is Our Approach To Treating Pancreatic Diseases?",
    Arr3Button: "Contact Us On WhatsApp",
    Arr3Title: "What Is Our Approach To Treating Pancreatic Diseases?",
    FlexSubTitle:
      "The pancreas serves both functions - exocrine, which involves secreting enzymes for the digestion of fat, proteins, carbohydrates, and endocrine, which involve the excretion of insulin and glucagon to control blood sugar level. The surgeons at Advance Surgicare specialize in the diagnosis and management of pancreatic diseases, including:",
    Arr3SubTitle:
      "The pancreas serves both functions - exocrine, which involves secreting enzymes for the digestion of fat, proteins, carbohydrates, and endocrine, which involve the excretion of insulin and glucagon to control blood sugar level and functions. The surgeons at Advance Surgicare specialize in the diagnosis and management of pancreatic diseases, including:",
    Array3: [
      {
        Title: "Acute & Chronic Pancreatitis ",
        SubTitle: "",
      },
      {
        Title: "Pancreatic Pseudocyst ",
        SubTitle: "",
      },
      {
        Title: "Pancreatic Abscess",
        SubTitle: "",
      },
      {
        Title: "Pancreatic Necrosis",
        SubTitle: "",
      },
      {
        Title: "Annular Pancreas",
        SubTitle: "",
      },
      {
        Title: "Pancreatic Divisum",
        SubTitle: "",
      },
      {
        Title: "Carcinoma Of Pancreas",
        SubTitle: "",
      },
      {
        Title: "Diabetes Mellitus ",
        SubTitle: "",
      },
      {
        Title: "Insulinomas ",
        SubTitle: "",
      },
      {
        Title: "Glucagonomas",
        SubTitle: "",
      },
      {
        Title: "Gastrinomas",
        SubTitle: "",
      },
    ],
    Paragraph:
      "The procedures that we offer for the treatment of the above-mentioned diseases in the pancreas are Whipple's Procedure for carcinoma of the head of the pancreas, Distal Pancreatectomy, Pancreatic Necrosectomy, Laparoscopic Pseudocyst Gastrostomy, Pancreatic Resections and Sleeve Gastrectomy for curing diabetes in fat and obese patients. ",
    FAQTitle:
      "Have questions and concerns related to Endocrine Surgery? We’ve got the answers!",
    FAQArray: [
      {
        Title: "Why Is Endocrine Surgery Recommended? ",
        SubTitle:
          "Our surgeons recommend endocrine surgery when the thyroid, parathyroid, adrenals, or pancreatic glands are not functioning properly and are resistant to medical therapy. As their malfunction can lead to serious health issues, hence removing them surgically is an effective treatment option.",
      },
      {
        Title:
          "Will the Surgeons Remove My Gland (Thyroid, Parathyroid, Adrenal)? ",
        SubTitle:
          "The adrenal gland requires removal if it is not active or in case a tumorous lump has grown in it. Moreover, if the adrenal glands are producing too much aldosterone, cortisol, noradrenaline, or adrenaline hormones, our surgeons may remove them using a key-hole (laparoscopic) surgery, as not doing so can put you at risk of cancer and metabolic complications.",
      },
      {
        Title: "What are The Symptoms of the Disease?",
        SubTitle:
          "Every gland has got its particular set of symptoms which are usually diagnosed by the endocrinologist physician and once the medical therapy fails the patient is referred to surgeons for the excision of the gland surgically.",
      },
      {
        Title: "What Are The Common Symptoms Of Endocrine Disorders?",
        SubTitle:
          "Some of the symptoms that are common among people suffering from endocrine disorders include fatigue, severe weakness, mood swings, unintended weight fluctuations, and changes in cholesterol/lipid or blood glucose levels.",
      },
      {
        Title: "Are All Endocrine Diseases Cancerous? ",
        SubTitle:
          "No, mostly they are just lumps or tumors that may or may not lead to cancer. Generally, they are treated by endocrine surgery that involves the removal of the tumor. However, if cancer has already developed in the endocrine system, it requires aggressive treatment involving chemotherapy/radiotherapy. ",
      },
      {
        Title: "How Do You Diagnose Endocrine Diseases?",
        SubTitle:
          "We diagnose endocrine diseases by measuring the hormone levels in the patient's blood mainly. Other than that, for the confirmation of the diagnosis, a CT scan, dual-energy X-ray absorptiometry, parathyroid ultrasound, and thyroid ultrasound is ordered. Once the disease is diagnosed, we create a personalized treatment plan for the patient that leads to a definitive cure",
      },
    ],
  },

  cancercare: {
    id: 4,
    path: "oncological",
    BannerTitle: "Laparoscopic Cancer Surgery",
    suggestion: `Laparoscopic Cancer  <span class="suggestion_text"> Surger</span>y`,
    BannerSubTitle: `Surgical cure for cancer that enables <span class="yellowText">oncological patients to live a normal life.</span>`,
    BannerImage: "OncologicalBannerImage",

    DivsTitle: "Comprehensive Treatment Modalities For Cancerous Tumors",
    DivsSubTitle:
      "The goal behind oncological (cancer) care offered by specialists at Advance Surgicare (chemotherapy, radiotherapy, immunotherapy, surgical therapy) is to achieve a complete cure and enable the cancer survivor to live a normal life. These treatment modalities are decided as the best clinical option for the specific cancer/tumor. The availability of specialists like an experienced oncological surgeon, and an empathetic oncologist along with a rehabilitation expert makes our cancer clinic the best choice for your cancer woes.",
    DivsImage: "ncologicaldivImage",
    Detailimage1: Oncology1,
    Detailimage2: Oncology2,
    Detailimage3: Oncology3,
    Arr1Title: "Cancers We Treat",
    buttonPhoneNumber: "+923229400555",
    phonenumber: "12345",
    Arr1SubTitle:
      "The following cancers are offered with a comprehensive treatment plan at our cancer care clinic.",
    DesktopData:
      "The diagnosis of cancer or cancer-causing tumors brings a flood of emotions and questions, including what to expect from the treatment. Without making as less disruptions as possible to the daily life of cancer patients, we follow these SOPs to provide them with the best oncological care.",
    firstArr: {
      first: [
        {
          title: "Breast Tumors/ Cancer",
          image: CancerBreast,
          bulletsArr: [
            `At Advance Surgicare, we believe in the preservation/ not removing breasts for cancers by offering patient-centric multi-modality <span class="detail_red">(chemotherapy, radiotherapy, immunotherapy and breast conservation surgery)</span>  treatment options.`,
            "While preserving/not removing the breasts, we stick to the basic principles of cancer surgeries.",
            `Strong radiological and lab backup <span class="detail_red">(frozen section biopsy)</span> helps us in removing cancer with <span class="detail_red">clear margins</span> and not the whole breast.`,
            `Gamma Probe helps to preserve the Axilla/ Armpit <span class="detail_red"> (Sentinal Lymph Node Sampling)</span> making the convalescence easy, with no arm swelling after the surgery (return to normal life within a week)`,
          ],
        },
        {
          title: "Thyroid Cancer",
          image: EndoThyroid,
          bulletsArr: [
            "Advance Surgicare offers surgery for all types of thyroid cancers.",
            "Advanced thyroid cancers which require removal of the lymph nodes (block dissection of the neck) are offered treatment via a multi-modality approach involving an endocrinologist and oncologist as well.",
            "For small thyroid nodules, especially in young people, we also offer endoscopic thyroidectomy (POET).",
            "Our thyroid clinic at Advance Surgicare also offers all sorts of medical management for thyroid disorders.",
          ],
        },
      ],
      second: [
        {
          title: "Upper Abdominal Cancers",
          image: CancerUpper,
          bulletsArr: [
            "Laparoscopic diagnosis, staging, and cancer surgery for the following are being done at Advance Surgicare:",
            "Stomach & Duodenum. Most patients present with vomiting, weight loss, and anemia.",
            "Liver and gallbladder. Symptoms range from jaundice to abdominal masses.",
            "Pancreas and spleen. Symptoms range from surgical jaundice to abdominal lumps and bumps.",
          ],
        },
        {
          title: "Lower Abdominal Cancers",
          image: CancerLower,
          bulletsArr: [
            "Lower abdominal cancers include tumors of the colon, rectum, appendix, and in ladies, uterus, and ovaries.",
            "Multi-modality treatment including complete laparoscopic removal of the above-mentioned tumors is being offered at Advance Surgicare with excellent outcomes.",
            "Oncological and gastroenterological backup makes Advance Surgicare a natural choice for the above-mentioned cancer resections.",
            "Radiology, pathology, and support for neo-adjuvant/ post-op chemotherapy/ radiation help the patient to return to normal life earlier than expected.",
          ],
        },
      ],
    },
    Array1: [
      {
        id: 1,
        readMore: true,

        Title: "Women’s Breasts",
        SubTitle:
          "The treatment for breast cancer depends on the present stage and tumor factors. Oncological surgery is often the best step to stop cancer from spreading. It typically involved removing the cancer-causing tumors in the breast, either with a mastectomy, lumpectomy, or breast conservation. Our surgeons have the expertise in performing these oncological surgeries laparoscopically and stopping the spread of cancer to other body parts.",
      },
      {
        id: 2,
        readMore: true,

        Title: "Gall Bladder",
        SubTitle:
          "Cancer cells can form in the tissues of a person's gall bladder - an organ lying just below the liver in the abdomen. Symptoms and signs of cancer in the gall bladder include pain, fever, and jaundice. Diagnosing this type of cancer is difficult and the only option to stop its spread in the body is to undergo an oncological surgery. ",
      },
      {
        id: 3,
        readMore: true,

        Title: "Liver",
        SubTitle:
          "A person is likely to get it if they are above the age of 60, and have certain medical conditions like parasites in the liver, diabetes, gall stones, liver cirrhosis, or hepatitis. Cancer in the liver can also be caused by cancerous cells spreading from any other body part. An oncological surgery is performed to remove cancerous cells from the liver and save the patient's life.",
      },
      {
        id: 4,
        readMore: true,

        Title: "Pancreas",
        SubTitle:
          "Pancreatic cancer contains the tissues of the pancreas, an organ in the abdomen of a human, lying behind the lower part of the stomach. Abdominal pain, loss of weight, jaundice, dark-colored urine, and blood clots are a sign of pancreatic cancer. Oncological surgery is performed as a potentially curative measure if cancer-causing tissues are contained within the pancreas. ",
      },
      {
        id: 5,
        readMore: true,

        Title: "Hernia",
        SubTitle:
          "A hernia occurs when any organ such as fatty tissue or intestine bulges through a weak area or opening in its surrounding connected tissues or muscles, mainly the abdomen. If left untreated, an incarcerated hernia becomes strangulated, cuts the blood flow, or even develops cancerous cells, which is a serious health concern.  In that case, surgical repair is considered necessary.",
      },
      {
        id: 6,
        readMore: true,

        Title: "Appendix ",
        SubTitle:
          "A thin pouch sitting in the lower right part of the stomach and attached to the large intestines is the appendix. When cells in the appendix grow significantly, cancer occurs. Symptoms include severe pain in the pelvic area or stomach, bloating, and fluid in the abdomen (ascites) Oncological surgery is performed to stop the tissue growth from the cells, termed a tumor and commonly identified as malignant. ",
      },
      {
        id: 7,
        readMore: true,

        Title: "Splenic ",
        SubTitle:
          "Spleen is located under the rib cage and upper left part of the stomach. It is a fist-sized organ that is filled with blood, stores blood cells, filters blood, and fights back infections. Cancer in the spleen is typically secondary, which means that they spread from any other body part to the spleen. Splenectomy or oncology surgery is performed on the organ to remove the cancerous cells.",
      },
      {
        id: 8,
        readMore: true,

        Title: "Thyroid",
        SubTitle:
          "Even though the etiology of thyroid cancer is not well-known, some factors put a person at risk of developing this disease. Similar to other types f cancers, family history is also known as a risk factor. An oncological surgery is performed for thyroid cancer, depending on the condition of the patient. The end goal of our surgeons is always to treat the condition to the best extent medically.",
      },
      {
        id: 9,
        readMore: true,

        Title: "Hepatobiliary Draining Channels  ",
        SubTitle:
          "Bile ducts or bile, gall bladder, and liver are termed hepatobiliary draining channels. Diseases and even cancer can be caused in the biliary system by toxic chemicals, metabolic disorders, parasitic infections, and cardiac failure. To cure cancerous cells in the system, we at Advance Surgicare provide specialized oncological care. Our surgeons work to diagnose and treat the biliary system through oncological surgery.",
      },
    ],

    // // grayBg: grayBg,
    // // upperBg: upperBg,
    floatingImg: HealthfloatingImg,
    lastPara:
      "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    lastTitle: "We Care For YOU",
    lastBulletArray: [
      "Highly-Experienced Surgeons",
      "Availability Of Female Surgeons",
      "Patient-Focused Care",
      "Comprehensive Diagnosis & Treatment",
      "Dedicated Post-Surgery Follow-Up",
      "Affability & Alacrity",
    ],

    Arr2Button: "Contact Us On WhatsApp",
    Arr2Title: "SOPs Followed For Cancer Care",
    Arr2SubTitle:
      "The diagnosis of cancer or cancer-causing tumors brings a flood of emotions and questions, including what to expect from the treatment. Without making as less disruptions as possible to the daily life of cancer patients, our oncological surgeon Dr. Sadia and other oncologists at Advance Surgicare follow SOPs to provide them the best oncological care. ",
    listView2: false,
    listView3: true,
    listView1: true,
    Array2: [
      {
        Title: "Screening Of Those Showing Symptoms",
      },
      {
        Title: "Early Detection Of Cancer",
      },
      {
        Title: "Staging To Determine Treatment Modality",
      },
      {
        Title: "Resections As A Curing Modality.",
      },
    ],

    FlexButton: "Call Now For Consultation",
    // FlexImage: Oncologicalfleximage,
    FlexTitle: "Laparoscopic Surgical Oncology For Cancer Patients",
    FlexSubTitle:
      "Until recently, surgeons have had second thoughts about performing key-hole/Laparoscopic oncological surgeries on patients. However, at Advance Surgicare, we do not avoid this approach, as it is not only about avoiding huge incisions. Laparoscopy also helps in accurate diagnosis, staging, and palliation, which are crucial for making an effective cancer treatment plan for the patient. Through laparoscopic oncology, our surgeons take control of the veins and arteries first, after which the tumor is manipulated to cure the patient without any fear of the tumor spreading to different parts of the body.",
    Arr3Button: "Call Now For Consultation",
    Arr3Title: "Cancer Screening Tests You Should Undergo",
    Arr3SubTitle:
      "Screening tests are aimed at diagnosing cancer before it starts showing symptoms and when the chances of successful treatment are higher. Our oncologist Dr. Sadia and other oncological surgeons recommend these tests to the patients at Advance Surgicare to diagnose cancer early, determine if it is spreading, monitor it during their treatment, and even after the oncological surgery.",
    Array3: [
      {
        Title: "MRI & CT Scans",
        SubTitle: "",
      },
      {
        Title: "Ultrasound",
        SubTitle: "",
      },
      {
        Title: "Nuclear Medicine Scans",
        SubTitle: "",
      },
      {
        Title: "Xrays  & Other Radiographic Tests ",
        SubTitle: "",
      },
      {
        Title: "Mamograms",
        SubTitle: "",
      },
      {
        Title: "Endoscopic Procedures",
        SubTitle: "",
      },
      {
        Title: "Biopsy and Cytology Tests",
        SubTitle: "",
      },
    ],
    FAQTitle:
      "Have questions and concerns related to Oncological (Cancer) Surgery? We’ve got the answers!",
    FAQArray: [
      {
        Title: "Are There Any Alternatives To Oncological Surgery?",
        SubTitle:
          " Surgery might be the only option available as a part of your treatment. Consult with our surgeon on why they recommend a surgical treatment option as compared to a non-surgical option and why undergoing surgery is the best option in your case. ",
      },
      {
        Title: "What Types Of Cancers Are Treated With Surgery?",
        SubTitle:
          " Sometimes, surgery alone can work for early-stage cancers in the gall bladder, liver, breasts, and other body parts too. However, it is usually a combination of oncological surgery for the removal of cancerous tumors, followed by chemotherapy/radiation therapy to stop them from prolapsing (coming back). We evaluate every cancer patient at Advance Surgicare and create individualized treatment plans to perform oncological surgery and improve their quality of life by curing cancer.",
      },
      {
        Title: "How Is An Oncological Surgery Typically Performed?",
        SubTitle:
          " When performed, the goal of an oncological surgery is to remove all the cancer-causing tissues and cells from the body. To do so, the surgeons at Advance Surgicare use different laparoscopic techniques to remove the cancer tissues and even some healthy tissues around, ensuring that they will not spread further or come back. ",
      },
      {
        Title: "How Soon After Diagnosis Of Cancer Will I Undergo Surgery?",
        SubTitle:
          "Cancer patients come to see the surgeons at Advance Surgicare soon after getting diagnosed to discuss their next step of treatment. Performing an oncological surgery depends on the approach that we take – as every case is unique. However, if surgery is recommended as the initial step, we operate within a week after meeting the patient. Occasionally, we delay the surgery in case we need to offer chemotherapy or/and radiotherapy to shrink the tumor after the diagnosis and try to treat the patient with different approaches for the maximum benefit. ",
      },
      {
        Title: "What Is The Aftercare Of Oncological Surgery?",
        SubTitle:
          "One issue that the patients experience after an oncological surgery is a slight pain in the operated area. Working closely with the anaesthetists/ pain specialists, our surgeons focus on maximizing the pain control for the patients, so that they can return to normal activity post-surgery as soon as possible. For some patients, it does take a few days before they are completely normal in their routine. However, for every patient, at Advance Surgicare, we optimize surgical techniques that are minimally invasive and minimize after-surgery pain. Additionally, we incorporate laparoscopic techniques to minimize the size of incisions and conserving the body image of the patients. Thus, also helping them with their mental health.          ",
      },
      {
        Title: "Are There Any Risks Involved With The Surgery?",
        SubTitle:
          "Although the risk of an oncological surgery depends on the organ it is being performed on, generally, there is a risk of pain, post-surgery infection, fatigue, and bleeding if you are taking certain medicines. However, all of these subside a few weeks after the surgery in most cases. ",
      },
    ],
  },

  vascular: {
    id: 5,
    path: "vascular",
    BannerTitle: "Vascular Surgery",
    suggestion: `Peripheral Vascular  <span class="suggestion_text"> Surger</span>y`,
    BannerSubTitle: `Our vascular surgeons specialize in <span class="yellowText">various methods of treating vascular conditions.</span>`,
    BannerImage: "VascularBannerImage",

    DivsTitle: "Comprehensive Approach To Treatment",
    DivsSubTitle: `Vascular conditions can keep you from living life to the fullest. Whether caused by an infection, injury, genetics, or any other condition, vascular diseases can cause serious health complications. The experienced vascular surgeons with an <span class="red_text_detail">IR BACKUP </span>  at Advance Surgicare specialize in performing various minimally-invasive procedures to treat vascular catastrophes, saving patients from amputations (limb chopping), thus improving patients' quality of life.`,
    DivsImage: "VasculardivImage",
    Detailimage1: Vascular1,
    Detailimage2: Vascular2,
    Detailimage3: VascularChange,
    Arr1Title: "Vascular Conditions We Treat",
    buttonPhoneNumber: "+923259400555",
    phonenumber: "123456",
    Arr1SubTitle: `We <span class="red_text_detail"> (Peripheral Vascular Surgeon + IR Specialists)</span> treat a spectrum of vascular diseases by performing interventions involving the arteries and veins of the abdomen, limbs, and neck.`,
    DesktopData:
      "Vascular diseases include conditions that affect the system of blood vessels and circulatory system. They range from diseases of the lymph vessels to veins, arteries, and blood disorders affecting circulation. Mostly, the following people and conditions are at risk of vascular diseases:",
    firstArr: {
      first: [
        {
          title: "Venous Diseases",
          image: VascularVenous,
          bulletsArr: [
            "DVT (Blood Clots In Deep Veins)",
            "Chronic Venous Insufficiency (Swollen Painful Leg)",
            "Venous Ulcers (Ulcers On the Ankle Due To Venous Hypertension)",
            "Varicose Veins (Swollen Blue Superficial Veins)",
            "Chronic Leg Aches In The Night",
            "Breathing Problems (Venous Clots/ Pulmonary Embolism)",
          ],
        },
        {
          title: "Arterial Diseases",
          image: VascularArterial,
          bulletsArr: [
            "Leg/Arm Pain On Exertion (Arterial Narrowing)",
            "Leg/Arm Pain During Rest (Arterial Blockage)",
            "Sudden Acute Leg/Arm Pain (Arterial Closure Due to blood clot, embolism)",
            "Blue/Black Discolouration Of Arm/Leg (Impending Gangrene)",
            "Stoke/ Half-Body Paralysis (Narrowing Or Blockage Of Neck Arteries)",
            "Dilated/ Aneurysmal Disease Of Arteries (Bleeding & Haemorrhage Due To Rupture)",
          ],
        },
      ],
    },
    Array1: [
      {
        id: 1,
        readMore: true,

        Title: "Deep Vein Thrombosis",
        SubTitle:
          "A blot clot in the deep veins, usually in the pelvis, calf, or thigh but can also occur in the arm, chest, or abdomen. It causes a warm sensation, pain, and sudden swelling. The clot can break free from the veins and block blood flow to the lungs, which is fatal. Diagnosis is made clinically through Duplex Ultrasound and Venography.",
      },
      {
        id: 2,
        readMore: true,

        Title: "Chronic Venous Insufficiency",
        SubTitle:
          "A condition in which the leg veins are unable to pump enough blood back to the heart. The symptoms are a feeling of tightness in the calves and swollen ankles. In some cases, the legs also feel tired, achy, restless, and heavy. People with varicose veins, pregnant women, smokers, and obese are at high risk of suffering from this condition.",
      },
      {
        id: 3,
        readMore: true,

        Title: "Venous Ulcers",
        SubTitle:
          "If the chronic venous insufficiency condition is left untreated, it results in venous ulcers - ulceration around the ankle, diagnosable by venography or sometimes duplex ultrasound. We treat the condition through Valve Repair, Bypass Venous Surgery, Vein Stripping, Ablation, and Sclerotherapy. For venous ulceration, we also use growth factors, Unna Boots, and hydrocolloid or transparent compression dressings.",
      },
      {
        id: 4,
        readMore: true,

        Title: "Varicose Veins",
        SubTitle:
          "Dilated and swollen veins that can be seen through the skin are varicose veins. They often appear twisted, bulging, and blue. If left untreated, the condition can worsen. The symptoms of this venous disease are restless, achy, and heavy legs, which may even result in night cramps. We treat the condition with minimally-invasive Vein Stripping, Ablation, and Sclerotherapy.",
      },
      {
        id: 5,
        readMore: true,

        Title: "Aortoiliac Atherosclerosis",
        SubTitle:
          "Blockage of the iliac arteries, resulting in cramps, pain in the legs, thighs, and buttocks, along with fatigue in the lower body. Some men suffering from this venous disease experience erectile dysfunction, numbness and coldness in limbs, cracked foot skin, sores on lower legs, toes, or heels, and weak leg muscles. The disease is treated by our surgeons through Bypass Grafting or Stenting.",
      },
      {
        id: 6,
        readMore: true,

        Title: "Carotid Artery",
        SubTitle:
          "An arterial disease that does not show symptoms early, but when symptomatic, the patient feels weakness, numbness, or a tingling sensation in one side of the body. Losing vision in one eye, being unable to speak, and moving the arm or leg sign a transient ischemic attack. If the signs stay after 24 hours, it is a Stroke. Our approach to treating this disease is Carotid Endarterectomy.",
      },
      {
        id: 7,
        readMore: true,

        Title: "Peripheral Vascular Disease",
        SubTitle:
          "A disorder of blood circulation that causes the blood vessels outside a patient’s brain and heart to spasm, block, or narrow. It can happen in both - veins and arteries. We perform vascular surgeries that repair the affected arteries or veins to prevent the disease, which can result in Chronic Limb Ischemia, Haemorrhage, Venous Ulceration, Stroke, and even Paralysis.",
      },
      {
        id: 8,
        readMore: true,

        Title: "Abdominal Aortic Aneurysm",
        SubTitle:
          "An aneurysm is a condition in which a weak blood vessel area bulges or expands significantly. Patients presenting with vague pain and pulsation in the abdomen, severe backache, or signs of leg artery disease suffer from an abdominal aortic aneurysm. We diagnose it by MRI, CT scan, Angiography, or Ultrasound. Treatment is a surgical graft repair if the rupture is greater than 5cm in diameter.",
      },
      {
        id: 9,
        readMore: true,

        Title: "Popliteal & Femoral Artery Aneurysm",
        SubTitle:
          "A kind of aneurysm that causes pulsating lump, arm or leg pain at rest or while exercising, ulceration or painful sores in toes or fingers, gangrene, nerve compression pain, or tissue death in case of blocked blood flow. Vascular surgeons at Advance Surgicare treat the condition with a Replacement or laparoscopic Surgical Bypass to improve the quality of life for the patients; without leaving any scars.",
      },
    ],

    // // grayBg: grayBg,
    // // upperBg: upperBg,
    floatingImg: HealthfloatingImg,
    lastPara:
      "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    lastTitle: "We Care For YOU",
    lastBulletArray: [
      "Highly-Experienced Surgeons",
      "Availability Of Female Surgeons",
      "Patient-Focused Care",
      "Comprehensive Diagnosis & Treatment",
      "Dedicated Post-Surgery Follow-Up",
      "Affability & Alacrity",
    ],

    Arr2Button: "Contact Us On WhatsApp",
    Arr2Title:
      "Who Are At The Risk Of Developing Peripheral Vascular Diseases?",
    Arr2SubTitle:
      "Vascular diseases include conditions that affect the blood vessels and circulatory system. They range from diseases of the lymph vessels to veins, arteries, and blood disorders affecting circulation. Mostly, the following people with specific conditions are at risk of vascular diseases such as:",
    listView2: false,
    listView3: true,
    listView1: true,
    Array2: [
      {
        Title: "Diabetics",
      },
      {
        Title: "Hypertensives",
      },
      {
        Title: "Chain Smokers",
      },
      {
        Title: "Hyperlipidemics",
      },
      {
        Title: "Cardiac Patients ",
      },
      {
        Title: "Morbidly Obese",
      },
      {
        Title: "Increased Blood Viscosity",
      },
      {
        Title: "Blood Clotting",
      },
      {
        Title: "High Homocysteine Level In Blood",
      },
    ],

    FlexButton: "Call Now For Consultation",
    // FlexImage: Vascularfleximage,
    FlexTitle: "Surgical Care For Your Vascular Health",
    FlexSubTitle: `The vascular surgeons and the <span class="text-danger"> IR  Specialists </span>at Advance Surgicare address patients with vascular conditions and deliver world-class vascular care through vascular interventions that leave no huge scars. Disorders of the vascular system are treated through medical therapy, interventional radiological procedures, and lastly, surgical reconstructions.`,
    Arr3Button: "Call Now For Consultation",
    Arr3Title: "What Tests Are Requires To get A Timely Diagnosis?",
    Arr3SubTitle:
      "Time is of the essence when it comes to diagnosing and managing vascular diseases, which is why patients showing symptoms should get themselves assessed as soon as possible. The investigating modalities includes both, minimally invasive and non- invasive options. Mainly, the screening tests we recommend include:",
    Array3: [
      {
        Title: "Blood Test For Cholesterol & Artery Diseases",
        SubTitle: "",
      },
      {
        Title: "Duplex/Doppler Ultrasound of Blood Vessels",
        SubTitle: "",
      },
      {
        Title: "Pulse Volume Recording",
        SubTitle: "",
      },
      {
        Title: "Magnetic Resonance Angiography",
        SubTitle: "",
      },
      {
        Title: "Computed Tomographic Angiography",
        SubTitle: "",
      },
      {
        Title: "Ankle-Brachial Index",
        SubTitle: "",
      },
      {
        Title:
          "Conventional Angiography/Angioplasty for Diagnosis/ Treatment Via State-Of-The-Art Cath Lab",
        SubTitle: "",
      },
    ],
    FAQTitle:
      "Have questions and concerns related to Vascular Surgery? We’ve got the answers! ",
    FAQArray: [
      {
        Title: "Are Vascular Diseases Common? ",
        SubTitle:
          "Yes, vascular diseases are a common medical condition that mainly affects adults - and nearly most of the diabetics/hypertensive, overweight, hyperlipidemics, and smokers over the age of 50.",
      },
      {
        Title: "What Are The Symptoms Of Vascular Diseases?",
        SubTitle:
          "Every vascular disease has its own specific symptoms. However, the common symptoms include soreness, itching, burning, cramping, and aching in the legs or arms, leading to firstly pain on exertion, then on rest, and if not treated in time, to gangrene and amputation. A simple screening can determine if you are suffering from vascular disease.",
      },
      {
        Title: "How Can I Avoid Vascular Diseases/ Problems?",
        SubTitle:
          "You can prevent/ avoid vascular diseases by managing diabetes, lowering cholesterol levels, controlling BP, quitting smoking, eating food with low calories and fats, maintaining ideal body weight, and exercising regularly. Other factors include obesity, age, and jobs that demand standing for long hours.",
      },
      {
        Title: "What If I Don’t Get My Vascular Disease Treated?",
        SubTitle:
          "Vascular diseases generally get worst with time If left untreated. Initially one might only feel restlessness in the affected leg or arm, but it can lead to first wasting of limb muscles and eventually gangrene (loss of body tissue).",
      },
      {
        Title: "Are Vascular Surgeries Painful?",
        SubTitle:
          "Most of the patients who get their vascular surgery done at Advance Surgicare report little to no pain during the procedure, as our approach is minimally-invasive and through IR (cath lab). However, we do give the patients a regional or local anesthetic for numbing the area before surgery.",
      },
      {
        Title: "How Long Will The Recovery Take?",
        SubTitle:
          "Our patients usually resume their daily activities immediately after their vascular surgery, as no huge incisions are made that take longer recovery time. However, for a few weeks post-surgery, we recommend regular walking that facilitates healing and avoiding prolonged standing periods.",
      },
      {
        Title: "Are There Any Complications In Performing Vascular Surgeries?",
        SubTitle:
          "Vascular surgeries performed by our experienced surgeons are rarely associated with any serious complications. Minor post-surgery side effects include itchiness, tingling, tightness, or tenderness in the area treated, which subsides in a few days.  ",
      },
      {
        Title: "Should I Exercise Post-Surgery?",
        SubTitle:
          "Generally, we do not recommend bed rest after vascular surgeries, being active is the key to a quick recovery. Staying inactive and not walking or exercising regularly can result in bed sores and blood clots. Hence, start stretching exercises and walking immediately. However, do wait for at least 2 weeks before resuming strenuous activities.",
      },
    ],
  },

  healthcare: {
    id: 6,
    path: "healthcare",
    BannerTitle: "Women’s Healthcare Clinic",
    suggestion: `Women’s Healthcare <span class="suggestion_text"> Surger</span>y `,
    BannerSubTitle: `Leading providers of <span class="yellowText">minimally-invasive surgeries</span> that <span class="yellowText">treat women's health problems.</span>`,
    BannerImage: "HealthcareBannerImage",

    DivsTitle: "Women’s Health Is Our Priority",
    DivsSubTitle:
      "Women have unique healthcare requirements and providing them is our priority at Advance Surgicare. From regular screenings of predominantly female cancers to gynaecology care and breast health issues, we provide women with personalized attention. Surgical procedures performed by experienced lady surgeons are minimally-invasive, breast conservative, and leave no scars on their bodies and mind. Thus, improving their health, which is why they make us their partner in their wellness and cancer survivorship. ",
    DivsImage: "HealthcaredivImage",
    Detailimage1: Health1,
    Detailimage2: Health2,
    Detailimage3: Health3,
    Arr1Title: "Specialized Women Care By Lady Surgeons ",
    buttonPhoneNumber: "+923359400555",
    phonenumber: "123456",
    Arr1SubTitle:
      "The healthcare needs of women change, and different concerns need specialized treatment. Our female surgeons at Advance Surgicare address those concerns specific to women in a minimally-invasive/ breast-conservative way.",
    DesktopData:
      "Surgeons at Advance Surgicare help women get relief from common health problems in the pelvic region. They use minimally invasive techniques to perform surgery, resulting in a short recovery and precise procedure. The following surgeries are performed on the ovaries, cervix, womb, and genital areas of women to treat their condition.",
    firstArr: {
      first: [
        {
          title: "Laparoscopic Gynaecological Services",
          image: WomenGynae,
          bulletsArr: [
            "Advance Surgicare offers advanced laparoscopic gynaecological services for the following women diseases.",
            "Disorders of the uterus resulting in painful/ massive menstruation (fibroids, endometrial thickening).",
            "Diseases of the ovaries and fallopian tubes (cysts and ectopic pregnancies)",
            "Laparoscopic surgeries for the cancer of the uterus, ovaries, and excision of the draining lymph nodes.",
          ],
        },
        {
          title: "Breast Related Problems",
          image: WomenBreastProblem,
          bulletsArr: [
            "The lady surgeons at Advance Surgicare offer unmatched services for the following breast-related problems.",
            "Breast lumps, painful breasts, nipple discharge, and ulcers on the breast skin.",
            "Personalized breast care examination routine/ breast screening for high-risk breast cancer patients.",
            "Breast care guidance during the lactation/feeding for first-time mothers.",
          ],
        },
      ],
      second: [
        {
          title: "Breast Conservation Surgery",
          image: WomenConservation,
          bulletsArr: [
            "Advance Surgicare’s lady surgeons are unique in offering breast cancer surgeries without the removal of the breasts.",
            "Women are offered minimally-invasive breast conservation surgeries with early rehabilitation to meet their needs.",
            "Besides breast conservation, the spread of tumors in the axilla/armpit is diagnosed via Gamma Probe during surgery.",
            "The Gamma Probe surgery (sentinel lymph node sampling) prevents the younger females from post-op arm swelling/pain.",
          ],
        },
        {
          title: "Surgeries For Pelvic Disorders & Piles Clinic",
          image: WomenPelvicDisorders,
          bulletsArr: [
            "Lady surgeons offer the following surgeries for non-cancerous pelvic disorders in females.",
            "Chronic pelvic pain resulting in strained marital relationships.",
            "Loss of urinary control especially in elderly females (pelvic floor strengthening/hitching)",
            "Piles (hemorrhoids), bleeding and pain during passage of stools, and discharge of puss around the anus.            ",
          ],
        },
      ],
    },
    Array1: [
      {
        id: 1,
        readMore: true,

        Title: "Laparoscopic Gynaecology",
        SubTitle:
          "Medications often do not relieve gynaecologic symptoms. Surgery remains the only effective treatment for the disorders of the uterus, ovaries, fallopian tubes, and endometrium. Our highly experienced gynaecologic surgeons perform laparoscopic gynaecological surgeries to treat gynaecologic conditions without making large incisions. The surgeries are known to leave minute scars, cause less pain, heal quickly, and carry no risk of infection.",
      },
      {
        id: 2,
        readMore: true,

        Title: "Breast Related Problems",
        SubTitle:
          "Problems related to breast health, like lumps, pain, and nipple discharge, demand excellent care without compromising on the convenience and comfort of women. The female surgeons at Advance Surgicare are unmatched in this region. Whether a woman consults with them for routine exams or high-risk breast cases, they provide the individual attention that meets the unique personal and clinical needs.",
      },
      {
        id: 3,
        readMore: true,

        Title: "Breast Conservation Surgery",
        SubTitle:
          "To stop breast cancer from spreading, women often have to get their breasts removed. However, because of cosmetic reasons, women do not prefer this option. We perform minimally-invasive breast conservation surgeries with early rehabilitation to meet their needs, as removal of the breast does not necessarily lead to lowering the chances of breast cancer recurrence. The surgery is also called lumpectomy or mastectomy.",
      },
      {
        id: 4,
        readMore: true,

        Title: "Laparoscopic Pelvic Surgery",
        SubTitle:
          "Certain conditions and diseases in the pelvic organs require undergoing pelvic surgery. Mainly, the surgery is performed on a female's rectum, bladder, or reproductive organs in case of appendicitis or ovarian cysts. We use a laparoscopic approach to perform the surgery, which involves making a small incision instead of a huge one needed for open surgery. It generally results in less pain and quicker recovery post-surgery.",
      },
      {
        readMore: true,
        id: 5,
        Title: "Endocrine Surgery ",
        SubTitle:
          "Thyroid diseases are eight times more common in women than in men. We majorly perform minimally-invasive endocrine surgeries on women to treat thyroid diseases - which mostly follow after pregnancy. Endocrine problems, especially thyroid dysfunction can lead to postpartum depression and even infertility. Recognizing the dysfunction and getting it treated through endocrine surgery is the only clinical treatment option.",
      },
      {
        id: 6,
        readMore: true,

        Title: "Infertility & Childbirth ",
        SubTitle:
          "Women who are obese or suffer from other gynaecologic problems face difficulty in getting pregnant and childbirth. The highly qualified female surgeons at Advance Surgicare address these problems in women by providing them personalized attention, diagnosing the problem, and performing less invasive surgeries. They provide the women's healthcare needs without leaving huge scars on their bodies, which is a preference.",
      },
    ],

    // // grayBg: grayBg,
    // // upperBg: upperBg,
    floatingImg: HealthfloatingImg,
    lastPara:
      "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    lastTitle: "We Care For YOU",
    lastBulletArray: [
      "Highly-Experienced Surgeons",
      "Availability Of Female Surgeons",
      "Patient-Focused Care",
      "Comprehensive Diagnosis & Treatment",
      "Dedicated Post-Surgery Follow-Up",
      "Affability & Alacrity",
    ],

    Arr2Button: "Contact Us On WhatsApp",
    Arr2Title: "Which Laparoscopic Pelvic Surgeries Do We Perform?",
    Arr2SubTitle:
      "Surgeons at Advance Surgicare help women get relief from common health problems in the pelvic region. They use minimally invasive techniques to perform surgery, resulting in a short recovery and precise procedure. The following surgeries are performed on the ovaries, cervix, womb, and genital areas of women to treat their condition. ",
    listView2: false,
    listView3: true,
    listView1: true,
    Array2: [
      {
        Title: "Lap Pelvic Sweep For Pelvic Cancer/Malignancies",
      },
      {
        Title: "Lap Hysterectomy (Removal Of Uterus)",
      },
      {
        Title: "Lap for Adnexal Mass (Biopsy & Removal)",
      },
      {
        Title: "Ovarian Cystectomies (For Cancer & Infertility)",
      },
      {
        Title: "Lap Procedures For Prolapse (Urinary Incontinence)",
      },
      {
        Title: "Lymph Adenectomy (Pelvic Cancers)",
      },
      {
        Title: "Lap Myomectomies For Uterine Fibroids",
      },
      {
        Title: "Lap Management For Vaginal Vault Prolapse",
      },
    ],

    FlexButton: "Call Now For Consultation",
    // FlexImage: Healthcarefleximage,
    FlexTitle: "Exceptional Breast Care For Women",
    FlexSubTitle:
      "Caring for women's breast health begins with understanding what is normal for the breasts. Any lumps in the breast, pain or nipple discharge are alarming and not normal. Lady surgeons at Advance Surgicare treat all kinds of breast-related problems in women through breast conservation and sentinel lymph node sampling. They are committed to helping women return to a healthy lifestyle post-surgery. ",
    Arr3Button: "Call Now For Consultation",
    Arr3Title: "Which Tests Can Help Diagnose Breast Cancer?",
    Arr3SubTitle:
      "Breast cancer in women is very common but treatable. To be aware of the abnormal signs in breasts, women have to be very observant so that the diagnosis can be made timely. To begin with, perform a self-exam while lying down, in the shower, or in front of a mirror. Check if there are any hard lumps in the breasts, thickened tissues, or changes in the appearance of the breasts, such as dimpling, rashes, or puckering of the skin. The following tests are recommended, with early initiation of the treatment: ",
    Array3: [
      {
        Title: "Breast Imaging",
        SubTitle: "",
      },
      {
        Title: "Mammography",
        SubTitle: "",
      },
      {
        Title: "Ultrasound",
        SubTitle: "",
      },
      {
        Title: "MRI",
        SubTitle: "",
      },
      {
        Title: "Biopsy",
        SubTitle: "",
      },
      {
        Title: "Receptor Assays",
        SubTitle: "",
      },
      {
        Title: "Genetic Mapping ",
        SubTitle: "",
      },
    ],
    FAQTitle:
      "Have any questions related to breast cancer or any other women’s health problems? We’ve got the answers!   ",
    FAQArray: [
      {
        Title: "Are Screening Tests Important For Every Woman?",
        SubTitle:
          "Yes, screening tests are procedures that help in the early detection of cancerous tumors and cells in the breasts. They identify the cancer- causing cells and tumors before they are noticeable by self-exam, which is why our surgeons recommend getting screened every year especially in ladies after attaining 40 years.",
      },
      {
        Title: "Will I Be Evaluated By A Female Surgeon? ",
        SubTitle:
          "Yes, we have specialized and experienced female surgeons at Advance Surgicare who evaluate every woman's symptoms carefully and provide personalized care. If diagnosed with any problem, they pinpoint the organs affected as well as the severity and then treat it accordingly. Some specific tests might be prescribed depending on the individual’s exam . ",
      },
      {
        Title: "Are All Surgeries Performed Laparoscopically?",
        SubTitle:
          "Laparoscopy ensures less pain, faster recovery, and most importantly - no scars. Hence, at Advance Surgicare, our approach to every surgery performed for the well-being of women is laparoscopic/minimally- invasive, whether their problem is related to the pelvic pathology or gynaecology.",
      },
      {
        Title: "Who Can Undergo Breast Conservation Surgery? ",
        SubTitle:
          "Women having early-stage breast cancer can undergo breast conservation surgery. If you have been diagnosed with cancer and are concerned about the removal of your breasts, this surgery is a good option because of its cosmetic as well as clinical benefits           ",
      },
      {
        Title: "Will I Experience Pain After The Surgery?",
        SubTitle:
          "Experiencing slight discomfort and pain post-surgery is normal, be it a breast conservation surgery, laparoscopic/minimally-invasive pelvic pathology or gynaecology. However, it diminishes over time.",
      },
      {
        Title: "What To Expect During Recovery? ",
        SubTitle:
          "For some days after the surgical procedure, you might experience discomfort and feel tired. You can also be sore around the area where the incision is made, but it will go away within a few days, if not hours.",
      },
      {
        Title: "Which Pain Relief Is Used During Laparoscopic Surgery?",
        SubTitle:
          " We perform laparoscopic surgeries at Advance Surgicare with general anaesthesia. It puts the patient to sleep without them having to feel any pain of even the minutest incisions made during surgery. ",
      },
      {
        Title: "How Soon After Surgery Can I Resume Regular Activities? ",
        SubTitle:
          " The surgeon will let you know when to get back to regular activities. For minor procedures like laparoscopic removal uterine fibroids, it is often one to two days post-surgery. However, returning to regular activities after complex surgical procedures like laparoscopic hysterectomy can take longer. You might be told to avoid any heavy activity post-operatively. ",
      },
    ],
  },
};
