import React, { useState, useEffect } from "react";
import "./header.css";
import drocross from "../Assets/images/services/NavImages/x.png";
import searchIcon from "../Assets/images/services/NavImages/searchIconNav.png";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HeaderSearch = ({ showIcon, setShowIcon }) => {
  const history = useHistory();
  const location = useLocation();
  const toggleIcons = () => {
    setShowIcon(!showIcon);
  };
  const [formState, setFormState] = useState("");

  const handleChange = (e) => {
    setFormState(e.target.value);
  };
  useEffect(() => {}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (location.pathname.toString().includes("services-detail")) {
      history.replace({
        pathname: "/search-result",
        state: {
          data: formState,
        },
      });
      setShowIcon(!showIcon);
      setFormState("");
    } else if (location.pathname.toString().includes("blogdetail")) {
      history.replace({
        pathname: "/search-result",
        state: {
          data: formState,
        },
      });
      setShowIcon(!showIcon);
      setFormState("");
    } else {
      history.push({
        pathname: "search-result",
        state: {
          data: formState,
        },
      });
      setShowIcon(!showIcon);
      setFormState("");
    }
  };

  return (
    <>
      <div className="search_div ">
        <form
          className="d-flex gap-1 search_form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <span className="search_span">
            <LazyLoadImage
              delayTime={500}
              src={searchIcon}
              alt="search_icon"
              onClick={toggleIcons}
            />
          </span>
          <input
            className="search_input"
            type="text"
            placeholder="Search"
            name="serachValue"
            value={formState}
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
        </form>
        <LazyLoadImage
          delayTime={500}
          src={drocross}
          alt="Cross Button"
          className="searchCrossButton d-md-none"
          onClick={toggleIcons}
        />
      </div>
    </>
  );
};

export default HeaderSearch;
