import React, { useState, useEffect } from "react";
import "../Blog/blog.css";
import { BlogData } from "./BlogData";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Blog = () => {
  const history = useHistory();
  const detailPage = (id) => {
    let path = `/blogdetail/${id}`;
    history.push({
      pathname: `/blogdetail/${id}`,
      state: {
        data: "blogs",
      },
    });
  };

  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 767 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 767 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  const [blog, setBlog] = useState(BlogData.slice(0, 10000));
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = isDesktop ? 9 : 3;
  const pagevisted = pageNumber * userPerPage;
  const DispalyUser = blog
    .slice(pagevisted, pagevisted + userPerPage)
    .map((data, i) => {
      return (
        <div className="destop_blog" key={i}>
          <div>
            <LazyLoadImage
              delayTime={500}
              className="blog_img"
              alt="blofimage"
              src={data.BlogImage}
            />
          </div>
          <div className="publish">
            <div className="publist_date">
              <LazyLoadImage
                delayTime={500}
                alt="calender"
                src={data.Calender}
              />
              <p>{data.Date}</p>
            </div>
            <div className="publish_by">
              <LazyLoadImage delayTime={500} alt="admin" src={data.Admin} />
              <p>{data.AdminName}</p>
            </div>
          </div>
          <div className="blog_data">
            <p className="blog_heading" onClick={() => detailPage(data.id)}>
              {data.BlogHeading}
            </p>
            <p className="blog_para">{data.BlogText}</p>
          </div>
          <div className="blog_btn">
            <button
              className="readmore_btn"
              onClick={() => detailPage(data.id)}
            >
              Read more <span className="readmore_icon">&gt;</span>
            </button>
          </div>
        </div>
      );
    });
  const pageCount = Math.ceil(blog.length / userPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="blog">
        <div className="blog_main">{DispalyUser}</div>
        <div className="destop_pagination_view">
          <div className="blog_value">
            <p>Showing 1- 10 of</p>
            <div className="blog_dropdwon">
              <select className="select_vlaue_blog">
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </div>
            <p>Blog(s)</p>
          </div>

          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousbttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </>
  );
};

export default Blog;
