import React, { useEffect, useState } from "react";
import "../ProviderService/providerserice.css";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import car11 from "../Assets/images/services/carouselImages/advance_laparoscopic_surgery_1_2x.webp";
import car12 from "../Assets/images/services/carouselImages/advance_laparoscopic_surgery_2_2x.webp";
import car13 from "../Assets/images/services/carouselImages/advance_laparoscopic_surgery_3_2x.webp";
import car21 from "../Assets/images/services/carouselImages/peripheral_vascular_clinic_1_2x.webp";
import car22 from "../Assets/images/services/carouselImages/peripheral_vascular_clinic_2_2x.webp";
import car23 from "../Assets/images/services/carouselImages/peripheral_vascular_clinic_3_2x.webp";
import car31 from "../Assets/images/services/carouselImages/endocrine_surgery_1_2x.webp";
import car32 from "../Assets/images/services/carouselImages/endocrine_surgery_2_2x.webp";
import car33 from "../Assets/images/services/carouselImages/endocrine_surgery_3_2x.webp";
import car41 from "../Assets/images/services/carouselImages/cancer_care_clinic_1_2x.webp";
import car42 from "../Assets/images/services/carouselImages/cancer_care_clinic_2_2x.webp";
import car43 from "../Assets/images/services/carouselImages/cancer_care_clinic_3_2x.webp";
import car51 from "../Assets/images/services/carouselImages/bariatric_obesity_weight_loss_clinic_1_2x.webp";
import car52 from "../Assets/images/services/carouselImages/bariatric_obesity_weight_loss_clinic_2_2x.webp";
import car53 from "../Assets/images/services/carouselImages/bariatric_obesity_weight_loss_clinic_3_2x.webp";
import car61 from "../Assets/images/services/carouselImages/women_s_healthcare_clinic_1_2x.webp";
import car62 from "../Assets/images/services/carouselImages/women_s_healthcare_clinic_2_2x.webp";
import car63 from "../Assets/images/services/carouselImages/women_s_healthcare_clinic_3_2x.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Provideservices = () => {
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [index3, setIndex3] = useState(0);
  const [index4, setIndex4] = useState(0);
  const [index5, setIndex5] = useState(0);
  const [index6, setIndex6] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleSelect2 = (selectedIndex2) => {
    setIndex2(selectedIndex2);
  };

  const handleSelect3 = (selectedIndex3) => {
    setIndex3(selectedIndex3);
  };
  const handleSelect4 = (selectedIndex4) => {
    setIndex4(selectedIndex4);
  };

  const handleSelect5 = (selectedIndex5) => {
    setIndex5(selectedIndex5);
  };

  const handleSelect6 = (selectedIndex6) => {
    setIndex6(selectedIndex6);
  };

  return (
    <>
      <div className="provider_main ">
        <p className="provider_heading">Our Surgical Care Services</p>
        <div className="care_services">
          <div className="new_care_services serives_new_">
            <Carousel
              interval={null}
              activeIndex={index}
              onSelect={handleSelect}
              className="upper_left_carousel"
            >
              <Carousel.Item>
                <div className="bg_CN first_Carousel upper_left_carousel ">
                  <Link
                    to={"services-detail/laparoscopic"}
                    className="provide_services_title"
                  >
                    Advance Laparoscopic Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car11}
                      style={{ width: "158px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Sugery without incisions (no cuts). No scars and no hernia
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN first_Carousel upper_left_carousel">
                  <Link
                    to={"services-detail/laparoscopic"}
                    className="provide_services_title"
                  >
                    Advance Laparoscopic Surgery
                  </Link>

                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car12}
                      style={{ width: "158px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Sugery without incisions (no cuts). No scars and no hernia
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN first_Carousel upper_left_carousel">
                  <Link
                    to={"services-detail/laparoscopic"}
                    className="provide_services_title"
                  >
                    Advance Laparoscopic Surgery
                  </Link>

                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car13}
                      style={{ width: "158px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Sugery without incisions (no cuts). No scars and no hernia
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
            <Carousel
              interval={null}
              activeIndex={index2}
              onSelect={handleSelect2}
            >
              <Carousel.Item>
                <div className="bg_CN">
                  <Link
                    to={"services-detail/vascular"}
                    className="provide_services_title"
                  >
                    Peripheral Vascular Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car21}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Leg and arm-saving surgery due to arterial and venous
                    disorders.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN">
                  <Link
                    to={"services-detail/vascular"}
                    className="provide_services_title"
                  >
                    Peripheral Vascular Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car22}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Leg and arm-saving surgery due to arterial and venous
                    disorders.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN">
                  <Link
                    to={"services-detail/vascular"}
                    className="provide_services_title"
                  >
                    Peripheral Vascular Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car23}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Leg and arm-saving surgery due to arterial and venous
                    disorders.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>

            <Carousel
              interval={null}
              activeIndex={index3}
              onSelect={handleSelect3}
              className=" upper_right_carousel"
            >
              <Carousel.Item>
                <div className="bg_CN upper_right_carousel">
                  <Link
                    to={"services-detail/endocrine"}
                    className="provide_services_title"
                  >
                    Endocrine Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car31}
                      style={{ width: "157px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Surgery for thyroid, parathyroid, and adrenal diseases.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN upper_right_carousel">
                  <Link
                    to={"services-detail/endocrine"}
                    className="provide_services_title"
                  >
                    Endocrine Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car32}
                      style={{ width: "157px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Surgery for thyroid, parathyroid, and adrenal diseases.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN upper_right_carousel">
                  <Link
                    to={"services-detail/endocrine"}
                    className="provide_services_title"
                  >
                    Endocrine Surgery
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car33}
                      style={{ width: "157px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Surgery for thyroid, parathyroid, and adrenal diseases.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

          <div className="new_care_services">
            <Carousel
              interval={null}
              activeIndex={index4}
              onSelect={handleSelect4}
              className=" bottom_left_carousel"
            >
              <Carousel.Item>
                <div className="bg_CN bottom_left_carousel">
                  <Link
                    to={"services-detail/cancercare"}
                    className="provide_services_title"
                  >
                    Cancer Care Clinic
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car41}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Advanced surgical therapy for cancer of the abdomen, pelvis,
                    thyroid, and breast.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN bottom_left_carousel">
                  <Link
                    to={"services-detail/cancercare"}
                    className="provide_services_title"
                  >
                    Cancer Care Clinic
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car42}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Advanced surgical therapy for cancer of the abdomen, pelvis,
                    thyroid, and breast.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN bottom_left_carousel">
                  <Link
                    to={"services-detail/cancercare"}
                    className="provide_services_title"
                  >
                    Cancer Care Clinic
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car43}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Advanced surgical therapy for cancer of the abdomen, pelvis,
                    thyroid, and breast.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>

            <Carousel
              interval={null}
              activeIndex={index5}
              onSelect={handleSelect5}
            >
              <Carousel.Item>
                <div className="bg_CN">
                  <Link
                    to={"services-detail/bariatric"}
                    className="provide_services_title bariatric_title"
                  >
                    Bariatric/Obesity Clinic{" "}
                    <span className="bariatric_span"> &nbsp;(Weight Loss)</span>
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car51}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Weight loss surgery curing diabetes and blood pressure.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN">
                  <Link
                    to={"services-detail/bariatric"}
                    className="provide_services_title bariatric_title"
                  >
                    Bariatric/Obesity Clinic{" "}
                    <span className="bariatric_span">&nbsp;(Weight Loss)</span>
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car52}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Weight loss surgery curing diabetes and blood pressure.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN">
                  <Link
                    to={"services-detail/bariatric"}
                    className="provide_services_title bariatric_title"
                  >
                    Bariatric/Obesity Clinic{" "}
                    <span className="bariatric_span"> &nbsp;(Weight Loss)</span>
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car53}
                      style={{ width: "108px", height: "108px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Weight loss surgery curing diabetes and blood pressure.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>

            <Carousel
              interval={null}
              activeIndex={index6}
              onSelect={handleSelect6}
              className=" bottom_right_carousel "
            >
              <Carousel.Item>
                <div className="bg_CN last_Carousel bottom_right_carousel ">
                  <Link
                    to={"services-detail/healthcare"}
                    className="provide_services_title"
                  >
                    Women’s Healthcare Clinic
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car61}
                      style={{ width: "158px", height: "125px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Expert lady surgeons offering surgical therapy for women's
                    healthcare.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN last_Carousel bottom_right_carousel">
                  <Link
                    to={"services-detail/healthcare"}
                    className="provide_services_title"
                  >
                    Women’s Healthcare Clinic
                  </Link>

                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car62}
                      style={{ width: "158px", height: "125px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Expert lady surgeons offering surgical therapy for women's
                    healthcare.
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="bg_CN last_Carousel bottom_right_carousel">
                  <Link
                    to={"services-detail/healthcare"}
                    className="provide_services_title"
                  >
                    Women’s Healthcare Clinic
                  </Link>
                  <div className="img_div">
                    <LazyLoadImage
                      delayTime={500}
                      src={car63}
                      style={{ width: "158px", height: "125px" }}
                      alt="dataImage"
                      className="carousel_img_hW "
                    />
                  </div>
                  <p className="provider_para">
                    Expert lady surgeons offering surgical therapy for women's
                    healthcare.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Provideservices;
