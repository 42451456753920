import Appointment from "./component/Appointment/Appointment";
import ContactUs from "./component/ContactUs/ContactUs";
import Faq from "./component/FAQS/Faqs";
import AboutPage from "./component/Pages/AboutPage";
import HomePage from "./component/Pages/HomePage";
import ServicesPageLayout from "./component/Pages/ServicesPageLayout";
import ServiceDetailPageContent from "./component/ServiceDetail/ServiceDetailPage";
import { servicesDataArray } from "./component/Data/DetailPageGenericArray";
import Privacy from "./component/Privacy/Privacy";
import Blog from "./component/Blog/Blog";
import BlogDetail from "./component/Blog/BlogDetail";
import SearchResult from "./component/SearchResult/SearchResult";

export const Routes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/about",
    component: <AboutPage />,
  },
  {
    path: "/services",
    component: <ServicesPageLayout />,
  },

  {
    path: "/contact-us",
    component: <ContactUs />,
  },
  {
    path: "/appointment",
    component: <Appointment />,
  },
  {
    path: "/faq",
    component: <Faq />,
  },
  {
    path: "/services-detail/:subservice",
    component: <ServiceDetailPageContent data={servicesDataArray} />,
  },
  {
    path: "/blog",
    component: <Blog />,
  },
  {
    path: "/blogdetail/:id",
    component: <BlogDetail />,
  },
  {
    path: "/:name",
    component: <Privacy />,
  },
  {
    path: "/search-result",
    component: <SearchResult />,
  },
];
