import React from "react";
import "../Accivement/accivement.css";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

AOS.init();
const Accivement = () => {
  return (
    <>
      <div className="accivement_main">
        <div className="accivement_data">
          <p className="accivement_heaading">Achievements Over The Years</p>
          <div className="accivement_cricle">
            <Row className="accivement_row">
              <Col
                xs={5}
                sm={2}
                style={{ margin: "0px 3px" }}
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
                className="accivemnet_box_destop"
              >
                <div className="accviment_box_black">
                  <div className="box_heading_yellow">
                    <span>+</span>
                    <CountUp
                      start={0}
                      end={20}
                      duration={1.75}
                      delay={1}
                      redraw={true}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </div>
                  <div className="box_line"></div>
                  <p className="box_para_yellow">
                    Years Of <br />
                    Experience
                  </p>
                </div>
              </Col>
              <Col
                xs={5}
                sm={2}
                style={{ margin: "0px 3px" }}
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
                className="accivemnet_box_destop"
              >
                <div className="accviment_box">
                  <div className="box_heading">
                    <span>+</span>
                    <CountUp
                      start={0}
                      end={150}
                      duration={1.75}
                      delay={1}
                      redraw={true}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span>K</span>
                  </div>
                  <div className="box_line"></div>
                  <p className="box_para">
                    Successful <br />
                    Surgeries
                  </p>
                </div>
              </Col>
              <Col
                xs={5}
                sm={2}
                style={{ margin: "0px 3px" }}
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
                className="accivemnet_box_destop order_div"
              >
                <div className="accviment_box_black">
                  <div className="box_heading_yellow">
                    <span>+</span>
                    <CountUp
                      start={0}
                      end={10}
                      duration={1.75}
                      delay={1}
                      redraw={true}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </div>
                  <div className="box_line"></div>
                  <p className="box_para_yellow">
                    Advanced Healthcare Professionals
                  </p>
                </div>
              </Col>
              <Col
                xs={5}
                sm={2}
                style={{ margin: "0px 3px" }}
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
                className="accivemnet_box_destop "
              >
                <div className="accviment_box">
                  <div className="box_heading">
                    <CountUp
                      start={0}
                      end={95}
                      duration={1.75}
                      delay={1}
                      redraw={true}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span>%</span>
                  </div>
                  <div className="box_line"></div>
                  <p className="box_para">
                    Patient <br />
                    Satisfaction
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accivement;
