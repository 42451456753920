import React, { useEffect, useState } from "react";
import "../Profile/profile.css";
import { Link as ScrollLink } from "react-scroll";
import img1 from "../Assets/images/services/imc_logo_2_2x.webp";
import img2 from "../Assets/images/services/genesis_logo_2x.webp";
import img_main from "../Assets/images/services/doctor_section_2x.webp";
import img_main_mob from "../Assets/images/services/doctor_image_mobile_4x.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Profile = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
  return (
    <>
      <div className="mbn_main2">
        <main className=" prof_main_div ">
          <div className="left_content_div">
            <div className="prof_div extra_padding">
              <p className="prof_name ">Prof. Surgeon Asim malik</p>
              <p className="prof_name_detail">
                Advance Laparoscopic, Vascular & Cancer Surgeon B.S (K.E), FRCS
                (UK), FICS (USA), DIP, LAP (Sb.France)
              </p>
            </div>

            <div className="second_div extra_padding">
              <p className="second_heading ">
                DIRECTOR & CHIEF (ADVANCE SURGICARE)
              </p>
              <p className="second_detail ">
                A Premier Laparoscopic & Vascular Surgery Clinic
              </p>
            </div>

            {/* <div className="practice_div "> */}
              {/* <p className="practice_letter extra_padding">
                CONSULTATION CLINICS
              </p>
              <div className="d-flex practice_img_div extra_padding">
                <LazyLoadImage
                  delayTime={500}
                  src={img1}
                  alt="logos"
                  className="practice_img_L"
                />
                <LazyLoadImage
                  delayTime={500}
                  src={img2}
                  alt="logos"
                  className="practice_img_R"
                />
              </div> */}
              {/* <div className="position_div ">
                <LazyLoadImage
                  delayTime={500}
                  src={img1}
                  alt="logos"
                  className="practice_img_L"
                />
                <LazyLoadImage
                  delayTime={500}
                  src={img2}
                  alt="logos"
                  className="practice_img_R"
                />
              </div> */}
            {/* </div> */}

            <div className="profile_button_div extra_padding">
              <ScrollLink
                to="contactus"
                spy={true}
                smooth={true}
                duration={200}
              >
                <button className="profile_btn ">Book A Consultation</button>
              </ScrollLink>
            </div>
          </div>
          <div className="Right_div ">
            <LazyLoadImage
              delayTime={500}
              src={isDesktop ? img_main : img_main_mob}
              alt="Profile Pic"
              className="profile_right"
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default Profile;
