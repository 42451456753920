import React from 'react'
import ServicesPage from '../Services/ServicesPage'

const ServicesPageLayout = () => {
  return (
    <>
      <ServicesPage />
    </>
  )
}

export default ServicesPageLayout