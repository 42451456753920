export const
PrivacyData ={
    "termofuse":{
        Heading1:"Terms of Use",
        Para1:"The terms such as ‘we’, ‘us’, or ‘our’ refer to Advance Surgicare and the entities that are affiliated with it. By accessing this website and its content, you accept these Terms of Use. All the material, information, and content on this website are only shared for informational purposes and do not serve as a substitute for consultation with a surgeon, diagnosis, and surgical treatment by a specialized surgeon. In case of any medical emergency, consult with a surgeon or doctor. Any kind of reliance on the information provided on the website is at your own risk. Advance Surgicare takes no responsibility for any consequences resulting from the misuse of information shared on this website. ",
        Heading2:"Electronic Communication",
        Para2:" Using this website or/and providing your personal information to Advance Surgicare through an appointment form or contact form establishes an electronic communication. By providing the information, you consent that you want to engage in electronic communication with Advance Surgicare. ",
        Heading3:"Unlawful Or Prohibited Use Of Intellectual Property",
        Para3:" As a condition of using this website, you warrant to Advance Surgicare that you will not use this website or any information provided on it for unlawful or prohibited purposes. Moreover, you will also not use this website in any manner that overburdens, disables, or damages the website or interfere with others’ use of the website. ",
        Subpara3:" Content that is included on the website, such as text, graphics, images, and other compilations is a property of Advance Surgicare. They are protected by copyrights and other implemented laws for the protection of intellectual property and proprietary rights. By using this website, you agree that you will not go against the proprietary notices, copyrights, and restrictions placed on the content of our website "
    },
    "privacypolicy":{
        Heading1:"Privacy Policy",
        Para1:"Everything mentioned in this Privacy Policy describes how Advance Surgicare collects user information through their interactions on this website. As a user of this website, you agree to all the terms mentioned in the Privacy Policy. We at Advance Surgicare reserve the right to change or update the Privacy Policy without any prior notice, and using this website automatically means that you have accepted the revisions in the terms of the Privacy Policy.",
        Heading2:"Collecting Personal Information",
        Para2:"As a user of this website, you fully understand how we use the personal information provided on it and have no objection. Technically, the personal information about the users that we collect is used for identifying, locating, and contacting them. Other than that, we use the information to:",
        Li1:"Make them aware of our products or services through email alerts, newsletters, and notifications.",
        Li2:"Track the location of the user from their browser or device to use it for marketing purposes.",
        Subpara2:"When you submit any information of a third party on this website, you claim of having the authority and permit us to use that information according to our Privacy Policy.",
        Heading3:"Use & Disclosure Of Personal Information",
        Para3:"Advance Surgicare uses the personal information of the users for responding to their requests – such as contacting us in case of a query and booking an appointment. We also use the personal information of the website users for marketing purposes and sending our administrative information.",
        Subpara3:"Personal information of the users may also be used for personalizing their experience while they interact with us through the website, including the services we are providing. If at any time in the future, the assets of Advance Surgicare are sold, we may also share or transfer the personal information to the third party as a part of our transaction.",
        Sub2para3:"To an extent that is permitted by the law, Advance Surgicare may also share the personal information of users with affiliated hospitals and care partners, who can use it for marketing and communication. Lastly, personal information can also be used by us for investigation in case of security breaches or otherwise cooperating with legal authorities."

        
    }
}
