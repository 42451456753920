import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { BlogData } from "./BlogData";
import facebook from "../Assets/images/services/meta_logo_2x.webp";
import insta from "../Assets/images/services/insta_logo_2x.webp";
import whatsap from "../Assets/images/whatsapp.png";
import "../Blog/blog.css";
import {
  FacebookShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from "react-share";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BlogDetail = () => {
  const { id } = useParams();
  const location = useLocation();

  const [blog, setBlog] = useState(BlogData);

  const ans = blog.filter((b) => b.id === id);
  return (
    <>
      <div className="blog">
        <div className="blog_main1">
          {ans.map((data, i) => (
            <div key={i} className="blog_detail_content_div">
              <div className="blog_detail_top_div d-md-flex justify-content-between">
                <div className="main_heading1_div">
                  <p className="main_heading1">{data.BlogName}</p>
                </div>

                <div className="publish_blog_detail d-flex">
                  <div className="publist_date_blog_detail d-flex align-items-center">
                    <LazyLoadImage
                      alt="calender"
                      delayTime={500}
                      src={data.Calender}
                    />
                    <p>{data.Date}</p>
                  </div>
                  <div className="publish_by_blog_detail d-flex align-items-center">
                    <LazyLoadImage
                      alt="calender"
                      delayTime={500}
                      src={data.Admin}
                    />
                    <p>{data.AdminName}</p>
                  </div>
                </div>
              </div>

              <div className="destop_blog_img_head">
                <LazyLoadImage
                  alt="calender"
                  delayTime={500}
                  className="blog_img1"
                  src={data.BlogImage}
                />
              </div>

              <div className="blog_data1">
                <p className="blog_heading1">{data.BlogHeading}</p>
                <p className="blog_para1">{data.BlogSubText}</p>
                <div className="destop_text">
                  <LazyLoadImage
                    alt="calender"
                    delayTime={500}
                    className="blogdataimg"
                    src={data.DetailImage}
                  />
                  <p className="blog_para1 margin_btm">{data.BlogSubText2}</p>
                </div>
                <p className="blog_para1 ">{data.BlogSubText1}</p>
              </div>
            </div>
          ))}
          <div className="share">
            <p>Share:</p>
            <FacebookShareButton
              url={`https://advancesurgicare.com${location.pathname}`}
            >
              <LazyLoadImage alt="facebook" delayTime={500} src={facebook} />
            </FacebookShareButton>
            <InstapaperShareButton
              url={`https://advancesurgicare.com${location.pathname}`}
            >
              <LazyLoadImage alt="insta" delayTime={500} src={insta} />
            </InstapaperShareButton>
            <WhatsappShareButton
              url={`https://advancesurgicare.com${location.pathname}`}
            >
              <LazyLoadImage alt="whatsap" delayTime={500} src={whatsap} />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
