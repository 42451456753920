const questions = [
  {
    id: "1",
    Title: "Why Are Surgeries Recommended? ",
    SubTitle:
      "Our surgeons clearly explain why a patient needs to undergo a surgical procedure. As the reasons behind surgery vary from one patient to another, they discuss the diagnosis, steps involved in the treatment procedure, and the aftercare so that the patients understand how their surgical problem will be addressed.",
    Open: false,
  },
  {
    id: "2",
    Title: "Are There Any Alternatives To Surgery?",
    SubTitle:
      " In some cases, non-surgical treatments, medications, and lifestyle changes help with improving the patient’s condition rather than performing surgery. However, if the convalescence is not as expected, surgery may be opted for as the last resort. Our surgeons at Advance Surgicare may help you with a definitive diagnosis and a better outcome.",
    Open: false,
  },
  {
    id: "3",
    Title: "What Are The Benefits Of Undergoing Surgery?",
    SubTitle:
      "Some conditions, if left untreated, lead to serious health problems and even death in the worst case. Surgery is the last option to treat some health conditions – such as cancer, obesity, and vascular diseases. Only a surgeon at Advance Surgicare can outline how a specific surgery can benefit you and how long the benefits will last. While the benefits of some surgeries last a lifetime, others might require repeat surgery. ",
    Open: false,
  },
  {
    id: "4",
    Title: "What Are The Risks & Complications Of Surgery?",
    SubTitle:
      " Surgeries always carry some risks, so it is important to discuss the risk and benefit ratio with surgeons at Advance Surgicare. They will brief the risks and complications associated with specific procedures in a detailed way so that patients comprehend them and deal with the anticipated complications in a better way ",
    Open: false,
  },
  {
    id: "5",
    Title: "What Type Of Anaesthesia Is Administered?",
    SubTitle:
      "Local, general, or regional anaesthesia is administered, depending on the type of surgical procedure you undergo at Advance Surgicare. Our anesthesia team will suggest and take care of administering anaesthesia in its specificity..",
    Open: false,
  },
  {
    id: "6",
    Title:
      "What If A Female Patient Wants To Get Treated By A Female Surgeon? ",
    SubTitle:
      "The 24/7 availability of qualified and experienced lady surgeons gives Advance Surgicare a unique position in the surgical fraternity where our female population can discuss their problems in detail without any hesitation in a comfortable and congenial environment. Not only the ladies can discuss their problems but can also be catered to by the lady surgeon when it comes to the actual surgical procedures.      ",
    Open: false,
  },
  {
    id: "7",
    Title: "What To Expect During Recovery?",
    SubTitle:
      "Recovery varies from one surgical procedure to another. During your consultation, ask the surgeon about what to expect in the initial days post-surgery, as well as what activities you need to avoid. Knowing everything ahead of time will help you to be prepared and recover quicker.  ",
    Open: false,
  },
  {
    id: "8",
    Title: "What Is The Cost Of Surgery Services?",
    SubTitle:
      "Surgery cost varies according to the treatment and health plan. Book a consultation with us to discuss the specific cost of your surgical treatment.  ",
    Open: false,
  },
];
export default questions;
