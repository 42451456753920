import React, { useEffect, useState } from "react";
import "./surgicare.css";
import image1 from "../Assets/images/services/A.png";
import image2 from "../Assets/images/services/A_mob.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AdvanceSurgicare = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= 768 ? true : false
  );
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  const updateDimensions = () => {
    setIsDesktop(window.innerWidth >= 768 ? true : false);
  };
  return (
    <>
      <div className="adv_sur_main">
        <div className="adv_2nd_div justify-content-md-center align-items-md-center mob_pt">
          <div className="A_div">
            <LazyLoadImage
              delayTime={500}
              src={isDesktop ? image1 : image2}
              alt="advance Image"
              className="A_img"
            />
          </div>
          <div className="d-flex flex-column-reverse flex-md-column justify-content-between">
            <div className="d-flex  para_main">
              <div className="d-flex flex-column para_left">
                <p className="dot_para">Asim's Team</p>
                <p className="dot_para">Ability</p>
                <p className="dot_para mid_para">Affability</p>
              </div>
              <div className=" para_right d-flex flex-column justify-content-md-center">
                <p className="dot_para">Availability</p>
                <p className="dot_para">Alacrity</p>
              </div>
            </div>
            <div className="vance_div d-flex">
              <h1 className="vance_font "> Dvance Surgicare </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceSurgicare;
