import React, { useEffect, useState } from "react";
import "../AboutUS/aboutus.css";
import { Row, Col } from "react-bootstrap";
import check from "../Assets/images/modern_technology_2x.webp";
import rs from "../Assets/images/affordable_pricing_2x.webp";
import doctor from "../Assets/images/certified_doctor_2x.webp";
import van from "../Assets/images/24_7_sevice_2x.webp";
import image1 from "../Assets/images/focus_on_you_1_1_2x.webp";
import image2 from "../Assets/images/focus_on_you_2_2x.webp";
import image3 from "../Assets/images/focus_on_you_3_2x.webp";
import { Link as ScrollLink } from "react-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutHead = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  return (
    <>
      <div className="about_mob">
        <div className="about_content_more">
          <div className="data_div">
            <h5>Surgical Care </h5>
            <h6>Our Focus Is On You</h6>
            <p>
              Advance Surgicare was founded by Dr. Aasim Malik - Chief Surgeon,
              to provide patient- focused surgical care in Pakistan. Always
              determined to exceed expectations in the surgeries he performs, he
              has 20+ years of experience and grabs every opportunity to give
              patients a new life. At the premier laparoscopic and vascular
              surgery clinic, Dr. Aasim Malik and his team of surgeons guarantee
              personalized surgical care tailored just for YOU.
            </p>
          </div>
          <div className="about_box">
            <Row className="last_screen_between">
              <Col xs={3} sm={12} className="flexSetting">
                <div className="about_box1">
                  <LazyLoadImage delayTime={500} alt="logo" src={check} />
                </div>
                <p className="mob_services_name">Modern Technology</p>
                {isDesktop && (
                  <div className="flexContent">
                    <p>Modern Technology</p>
                    <p>
                      We use the latest medical technology and equipment to
                      diagnose patients and perform surgeries.
                    </p>
                  </div>
                )}
              </Col>
              <Col xs={3} sm={12} className="flexSetting">
                <div className="about_box2">
                  <img src={rs} alt="logo" />
                </div>
                <p className="mob_services_name"> Affordable Pricing</p>
                {isDesktop && (
                  <div className="flexContent">
                    <p>Affordable Pricing</p>
                    <p>
                      The male and female surgeons at Advance Surgicare are
                      qualified and experienced to perform complicated
                      surgeries.
                    </p>
                  </div>
                )}
              </Col>
              <Col xs={3} sm={12} className="flexSetting">
                <div className="about_box1">
                  <LazyLoadImage delayTime={500} alt="logo" src={doctor} />
                </div>
                <p className="mob_services_name">Certified Doctor</p>
                {isDesktop && (
                  <div className="flexContent">
                    <p>Certified Doctor</p>
                    <p>
                      Patients are our topmost priority and we believe in
                      providing them with convenient and easy access to surgical
                      care.
                    </p>
                  </div>
                )}
              </Col>
              <Col xs={3} sm={12} className="flexSetting">
                <div className="about_box2">
                  <LazyLoadImage delayTime={500} alt="logo" src={van} />
                </div>
                <p className="mob_services_name">24/7 Sevice</p>
                {isDesktop && (
                  <div className="flexContent">
                    <p>24/7 Service</p>
                    <p>
                      Our approach to surgical care does not only cure the
                      patients but also improves their quality of life for the
                      years to come.
                    </p>
                  </div>
                )}
              </Col>
              <ScrollLink
                to="contactus"
                spy={true}
                smooth={true}
                duration={200}
              >
                <div className="aboutus_head_btn">
                  <button type="">Contact Us For Consultation</button>
                </div>
              </ScrollLink>
            </Row>
          </div>
        </div>
        {isDesktop && (
          <div className="div-images">
            <LazyLoadImage delayTime={500} alt="iamge1" src={image1} />
            <LazyLoadImage delayTime={500} alt="iamge2" src={image2} />
            <LazyLoadImage delayTime={500} alt="iamge3" src={image3} />
          </div>
        )}
      </div>
    </>
  );
};

export default AboutHead;
