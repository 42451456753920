import React, { useState, useEffect } from "react";
import "./SearchResult.css";
import { useHistory, useLocation } from "react-router-dom";
import { StaticResultData } from "./StaticResultData.js";
import ReactPaginate from "react-paginate";

const SearchResult = () => {
  const history = useHistory();
  const location = useLocation();
  const [formState, setFormState] = useState(location.state.data || "");
  const [foundUsers, setFoundUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (formState.length > 0) {
      let temp2 = [];
      StaticResultData.cardShapeData?.map((item, index) => {
        let pushItem = false;
        if (
          item.cardName
            .toLowerCase()
            .includes(formState.toString().toLowerCase())
        )
          pushItem = true;

        item.cardData.map((cardDataItem) => {
          if (
            cardDataItem
              .toLowerCase()
              .includes(formState.toString().toLowerCase())
          )
            pushItem = true;
        });
        if (pushItem) temp2.push(item);
      });

      StaticResultData.paraShapeData?.map((item, index) => {
        if (
          item.heading
            .toLowerCase()
            .includes(formState.toString().toLowerCase()) ||
          item.para.toLowerCase().includes(formState.toString().toLowerCase())
        )
          temp2.push(item);
      });
      setFoundUsers(temp2);
    } else {
      setFoundUsers([]);
    }
  }, [formState, location.state.data.input]);

  const ResultsPerPage = 6;
  const pagevisted = pageNumber * ResultsPerPage;
  const DispalySearchResults =
    foundUsers.length > 0
      ? foundUsers
          ?.slice(pagevisted, pagevisted + ResultsPerPage)
          ?.map((item, index) =>
            item.heading ? (
              <div className="results_toShow_div" key={index}>
                <p
                  className="results_heading"
                  onClick={(e) => pushToPage(item.path, item.id)}
                >
                  {item.heading}
                </p>
                <p className="results_para">{item.para}</p>
              </div>
            ) : (
              <div className="results_toShow_div" key={index}>
                <p
                  className="results_heading"
                  onClick={(e) => pushToPage(item.path, item.id)}
                >
                  {item.cardName}
                </p>
                <ul className="cardData">
                  {item.cardData?.map((cardDataItem) => (
                    <li className="cardItem">{cardDataItem}</li>
                  ))}
                </ul>
              </div>
            )
          )
      : "";

  const pushToPage = (path, id) => {
    history.push({
      pathname: path,
      state: {
        data: id,
      },
    });
  };

  const pageCount = Math.ceil(foundUsers.length / ResultsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleInput = (e) => {
    setFormState(e.target.value);
  };

  return (
    <>
      <div className="searchResult_container">
        <div className="searchResult_content_div">
          {formState.length > 0 && (
            <p className="seachResult_heading">
              Search results for keyword: {formState}
            </p>
          )}
          <form className="searchResult_form" onSubmit={(e) => handleSubmit(e)}>
            <input
              className="searchResult_input"
              type="text"
              placeholder="Surgery"
              value={formState}
              onChange={(e) => handleInput(e)}
            />
            <button className="searchResult_btn">Search</button>
          </form>
          {DispalySearchResults}

          {foundUsers.length > 0 ? (
            <div className="searchResult_pagination_div">
              <div className="destop_pagination_view">
                <div className="blog_value">
                  <p>Showing {pageNumber + 1} of</p>
                  <div className="blog_dropdwon">
                    <select className="select_vlaue_blog">
                      <option defaultValue value={pageCount}>
                        {pageCount}
                      </option>
                    </select>
                  </div>
                </div>

                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousbttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          ) : (
            <h1 className="seachResult_heading">No Match Found . . . . .</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResult;
