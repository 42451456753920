import React, { useEffect, useState } from "react";
import team from "../Assets/images/team.webp";
import profAsim from "../Assets/images/services/surgeonImages/prof_surgeon_asim_malik_4x.webp";
import drHisham from "../Assets/images/services/surgeonImages/dr_hisham_ahmed_4x.webp";
import drIram from "../Assets/images/services/surgeonImages/dr_iram_naseem_4x.webp";
import drOsama from "../Assets/images/services/surgeonImages/dr_osama_ather_4x.webp";
import drSadia from "../Assets/images/services/surgeonImages/dr_sadia_4x.webp";
import drSaima from "../Assets/images/services/surgeonImages/dr_saima_amjad_4x.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../AboutUS/progress.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MultiCarousel = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 767 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 767 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={false}
        showDots={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        // deviceType={deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        slidesToSlide={1}
      >
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={profAsim}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Prof. Surgeon Asim Malik</p>
            <p className="surgeons_specialization">Chief of Surgery</p>
          </div>
        </div>
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={drOsama}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Dr. Osama Ather</p>
            <p className="surgeons_specialization">
              Incharge Advance Laparoscopy & Oncology Surgery
            </p>
          </div>
        </div>
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={drIram}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Dr. Iram Naseem</p>
            <p className="surgeons_specialization">
              Incharge Well Women Clinic & Gynaecology
            </p>
          </div>
        </div>
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={drSaima}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Dr. Saima Amjad</p>
            <p className="surgeons_specialization">
              Incharge Bariatric Weight Loss & Endocrine Surgery
            </p>
          </div>
        </div>
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={drHisham}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Dr. Hisham Ahmed</p>
            <p className="surgeons_specialization">
              Incharge Vascular & Trauma Services
            </p>
          </div>
        </div>
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={team}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Dr. Tasadduq Hussain </p>
            <p className="surgeons_specialization">Emergency Responder</p>
          </div>
        </div>
        <div className="surgeons_card">
          <LazyLoadImage
            delayTime={500}
            className="meet_surgeons_img"
            src={drSadia}
          />
          <div className="surgeons_info_div">
            <p className="surgeons_name">Dr. Sadia</p>
            <p className="surgeons_specialization">Oncology Surgeon</p>
          </div>
        </div>
      </Carousel>
    </>
  );
};

export default MultiCarousel;
