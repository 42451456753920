import React from "react";
import Accivement from "../Accivement/Accivement";
import Home from "../Home/Home";
import Profile from "../Profile/Profile";
import Provideservices from "../ProviderService/Provideservices";
import Surgicare from "../Surgicare/Surgicare";
import Testtominal from "../Testominal/Testtominal";

const HomePage = () => {
  return (
    <>
      <Home
        Heading={"Advance Surgicare"}
        SubHeading={"A Consortium Of Trained and Highly Skilled Surgeons Providing Services At Jail Road And In DHA"}
        // OneLiner={
        //   "Surgeons providing services at Jail Road and in DHA"
        // }
        Button={"Consult Now"}
      />
      <Provideservices />
      <Surgicare
        Paragraph2={
          "We are a surgical practice committed to meeting excellence. Our philosophy at Advanced Surgicare is to provide every patient with personalized care. Each of our surgeons excels at a range of surgical procedures, including minimally-invasive laparoscopic surgeries - our specialty. They are also lifelong learners whose dedication to excellence translated into exceptional outcomes and care for patients."
        }
        Button={"Read More About Us"}
      />
      <div className="setBgHomePage">
        <Accivement />
        <Profile />
        <Testtominal
          Para1={
            "I would like to thank everyone at Advanced Surgicare for doing my son's gall bladder surgery. Special thanks to Dr. Asim, of course. His excellent surgical skills cured my son and he is well now. Words aren't really enough for thanking you for your care and kindness during our time of need."
          }
          Name1={"Ahmed"}
          Para2={
            "I am so relieved that my breast conservation surgery is finally done. Everyone was so kind and helped me when I  panicked before the surgery. Thank you for saving my life that day and calming me down."
          }
          Name2={"Fariha"}
          Para3={
            "Dr. Asim provided me will all the information related to my weight loss surgery and responded to all my concerns and questions. At all times during the treatment, I had great confidence in him and his team. They provided me with the best possible care!"
          }
          Name3={"Murtaza"}
          Para4={
            "I want to praise your surgeons for treating me so well while repairing my hernia. I was very nervous because it was my first surgery. I'm surely going to book an appointment with Advance Surgicare in case of any emergency of this sort."
          }
          Name4={"Areeba"}
        />
      </div>
    </>
  );
};

export default HomePage;
