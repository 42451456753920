import React from "react";
import "./Faqs.css";
import Accordian from "./Accordian";
const Faq = ({ data }) => {
  const url = window.location.pathname;
  return (
    <>
      <div className={url === "/faq" ? "faq_page" : "otherpage_faq"}>
        <div className={url === "/faq" ? "faq_main" : "faq_main_otherpages "}>
          <div
            className={data ? "shipping_heading_servives" : "shipping_heading "}
          >
            Frequently Asked Questions
          </div>
          <div className={data ? "shipping_heading2" : "shipping_heading1"}>
            FAQ’S
          </div>

          <div
            className={
              url === "/faq"
                ? "shipping_faq_head_para"
                : "shipping_faq_head_para_otherpage"
            }
          >
            {data?.FAQTitle ? (
              data?.FAQTitle
            ) : (
              <div
                className={
                  data ? "shipping_faq_head_line1" : "shipping_faq_head_line"
                }
              >
                Have any questions related to the surgical services we provide
                at Advance Surgicare? We've got the answers!
              </div>
            )}{" "}
          </div>
        </div>
        <Accordian data={data} />
      </div>
    </>
  );
};

export default Faq;
